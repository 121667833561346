import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Nav from "../components/navbar";
import {
  Button,
  Divider,
  HStack,
  Text,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  useToast,
  Center,
  StatHelpText,
  StatArrow,
  Card,
  Table,
  TableContainer,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  getCurrentWeekRange,
  getHeadersForRequest,
  numberWithCommas,
} from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { ProductionList, RatioResponse } from "../utils/types";
import moment from "moment";
import Line from "../components/analytics/LineTemplate";
import CustomerPurchasesChart from "../components/analytics/CustomersPurchase";
import CustomerPurchasesPieChart from "../components/analytics/PieTemplate";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();
  const handleLogout = () => {
    Cookies.remove("userName");
    Cookies.remove("userToken");
    Cookies.remove("emailAddress");
    Cookies.remove("deviceId");
    toast({ title: "Session expired, please login again", status: "warning" });
    navigate("/login");
  };

  const { t, changeLanguage } = useCustomTranslation();

  const menuItems1 = [
    {
      icon: "calculator-variant",
      name: t("SalesOrder"),
      nav: "SalesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("Expenses"),
      nav: "ExpensesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("Credit Note"),
      nav: "CreditNote",
    },
    {
      icon: "calculator-variant",
      name: t("DirectExpenses"),
      nav: "OtherExpenses",
    },
    {
      icon: "calculator-variant",
      name: t("Payments"),
      nav: "Payments",
    }, 
    {
      icon: "calculator-variant",
      name: t("Transfers"),
      nav: "Transfers",
    },
    {
      icon: "calculator-variant",
      name: t("External Transfer"),
      nav: "TransfersExternal",
    },
  ];

  const menuItems1A = [
    {
      icon: "calculator-variant",
      name: t("SalesOrder"),
      nav: "SalesCalculatorGeneral",
    },
    {
      icon: "calculator-variant",
      name: t("PurchaseOrder"),
      nav: "ExpensesCalculator",
    },
    {
      icon: "calculator-variant",
      name: t("Payments"),
      nav: "Payments",
    },
    {
      icon: "calculator-variant",
      name: t("Transfers"),
      nav: "Transfers",
    },
  ];

  const menuItems2 = [
    { icon: "people-alt", name: t("Customers"), nav: "Customers" },
    { icon: "people-alt", name: t("Suppliers"), nav: "Suppliers" },
    { icon: "people-alt", name: t("Accounts"), nav: "Accounts" },
    { icon: "people-alt", name: t("Fryer"), nav: "Fryer" },
    { icon: "people-alt", name: t("Production"), nav: "Production" },
    { icon: "people-alt", name: t("Stock"), nav: "Stock" },
  ];

  const menuItems4 = [
    {
      icon: "calculator-variant",
      name: t("PendingOrders"),
      nav: "PendingOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentOrders"),
      nav: "RecentOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentPayments"),
      nav: "RecentPayments",
    },
    {
      icon: "calculator-variant",
      name: t("RecentExpenses"),
      nav: "RecentExpenses",
    },
  ];

  const menuItems2A = [
    { icon: "people-alt", name: t("Products"), nav: "Products" },
    { icon: "people-alt", name: t("Customers"), nav: "Customers" },
    { icon: "people-alt", name: t("Suppliers"), nav: "Suppliers" },
    { icon: "people-alt", name: t("Accounts"), nav: "Accounts" },
    {
      icon: "calculator-variant",
      name: t("PendingOrders"),
      nav: "PendingOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentOrders"),
      nav: "RecentOrders",
    },
    {
      icon: "calculator-variant",
      name: t("RecentPayments"),
      nav: "RecentPayments",
    },
    {
      icon: "calculator-variant",
      name: t("RecentExpenses"),
      nav: "RecentExpenses",
    },
  ];

  const menuItems3 = [
    { icon: "calculator-variant", name: t("Reports"), nav: "Reports" },
    { icon: "calculator-variant", name: t("Analytics"), nav: "Analytics" },
    { icon: "calculator-variant", name: t("DCR"), nav: "PapaDCR" },
  ];

  useEffect(() => {
    handleGetBalances();
    getSessions();
    fetchRatios();
  }, []);

  const [totalReceivables, setTotalReceivables] = useState<number>(0);
  const [totalPayables, setTotalPayables] = useState<number>(0);
  const [thisWeekProduction, setThisWeekProduction] = useState<number>(0);
  const [lastWeekProduction, setLastWeekProduction] = useState<number>(0);
  const [lasterWeekProduction, setLasterWeekProduction] = useState<number>(0);
  const [productionDifference, setProductionDifference] = useState<number>(0);
  const [productionDifferenceState, setProductionDifferenceState] = useState<boolean>(false);
  const [productionLasterDifference, setProductionLasterDifference] = useState<number>(0);
  const [productionLasterDifferenceState, setProductionLasterDifferenceState] = useState<boolean>(false);
  const [todayProduction, setTodayProduction] = useState<ProductionList[]>([]);
  const [thisWeekSale, setThisWeekSale] = useState<number>(0);
  const [lastWeekSale, setLastWeekSale] = useState<number>(0);
  const [saleDifference, setSaleDifference] = useState<number>(0);
  const [saleDifferenceState, setDifferenceState] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [receivableRatio, setReceivableRatio] = useState<number>(0);

  const handleGetBalances = async () => {
    setIsLoading(true);

    const payable = await getPayablesBalance();
    const receivable = await getReceivablesBalance();

    // console.log(payable);

    if (payable && receivable) {
      setIsLoading(false);
    }
  };

  const getPayablesBalance = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/totalPayables`,
        { headers }
      );
      // console.log(response);
      if (response.data.message === "Invalid Token") {
        handleLogout();
        return;
      }
      setTotalPayables(response.data.total_sum);

      return { success: 1 };
    } catch (error) {
      console.error(error);
      return { success: 1 };
    }
  };

  const getReceivablesBalance = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<any>(
        `${process.env.REACT_APP_TEST_API_URL}/payments/customerBalances`,
        { headers }
      );

      if (response.data.message === "Invalid Token") {
        handleLogout();
        return;
      }
      setTotalReceivables(
        response.data.reduce(
          (total: any, customer: any) => total + Number(customer.balance),
          0
        )
      );
      return { success: 1 };
    } catch (error) {
      return { success: 1 };
    }
  };

  function getSalutation() {
    const currentHour = new Date().getHours();

    if (currentHour >= 0 && currentHour < 12) {
      return t("goodMorning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("goodAfternoon");
    } else {
      return t("goodEvening");
    }
  }

  const getSessions = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get<ProductionList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/production/getAll`,
        { headers }
      );

      // console.log(response);
      // Sort filtered customers by balance in descending order
      // const sortedFilteredData = response.data.sort(
      //   (a, b) => Number(b.date) - Number(a.date)
      // );

      if (response.data.length > 0) {
        const { monday: thisMonday, sunday: thisSunday } = getCurrentWeekRange(0);
        const { monday: lastMonday, sunday: lastSunday } = getCurrentWeekRange(1);
        const { monday: lasterMonday, sunday: lasterSunday } = getCurrentWeekRange(2);

        // Filter and sum values within this work week

        const calculateWeeklySum = (start: Date, end: Date) =>
          response.data
            .filter((item) => {
              const itemDate = new Date(item.date);
              return itemDate >= start && itemDate <= end;
            })
            .reduce(
              (acc, item) =>
                acc + (item.finishedMaterialWeight - item.burntWeight),
              0
            );

        // const sum = response.data
        //   .filter((item) => {
        //     const itemDate = new Date(item.date);
        //     return itemDate >= monday && itemDate <= sunday;
        //   })
        //   .reduce(
        //     (acc, item) => acc + (item.finishedMaterialWeight - item.burntWeight),
        //     0
        //   );

        const thisWeek = calculateWeeklySum(thisMonday, thisSunday);
        const lastWeek = calculateWeeklySum(lastMonday, lastSunday);
        const lasterWeek = calculateWeeklySum(lasterMonday, lasterSunday);
        setThisWeekProduction(thisWeek);
        setLastWeekProduction(lastWeek);
        setLasterWeekProduction(lasterWeek);

        const filteredProduction = response.data
          .filter((item) => {
            const itemDate = new Date(item.date);
            const itemUTC = new Date(
              itemDate.getUTCFullYear(),
              itemDate.getUTCMonth(),
              itemDate.getUTCDate()
            );

            const today = new Date();
            const todayUTC = new Date(
              today.getUTCFullYear(),
              today.getUTCMonth(),
              today.getUTCDate()
            );

            const fiveDaysAgoUTC = new Date(todayUTC);
            fiveDaysAgoUTC.setDate(todayUTC.getDate() - 5);

            return itemUTC >= fiveDaysAgoUTC && itemUTC <= todayUTC;
          })
          .sort((a, b) => {
            const dateA = new Date(a.date).getTime(); // Convert to timestamp
            const dateB = new Date(b.date).getTime(); // Convert to timestamp
            return dateB - dateA; // Sort in descending order
          });

        setTodayProduction(filteredProduction);

        const diff = ((thisWeek - lastWeek) / lastWeek) * 100;
        setProductionDifference(Math.abs(diff));
        setProductionDifferenceState(diff > 0);

        const lasterDiff = ((lastWeek - lasterWeek) / lasterWeek) * 100;
        setProductionLasterDifference(Math.abs(lasterDiff));
        setProductionLasterDifferenceState(lasterDiff > 0);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchRatios = async () => {
    try {
      const headers = await getHeadersForRequest();

      const response = await axios.get<RatioResponse>(
        `${process.env.REACT_APP_TEST_API_URL}/analytics/receivablesRatio`,
        {
          headers,
        }
      );
      console.log("Ratios Response:", response.data); // Log API response

      setReceivableRatio(response.data.turnover_ratio);
      // setIsLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const deviceDetails = {
  //   userAgent: navigator.userAgent,
  //   platform: navigator.platform,
  //   language: navigator.language,
  //   cookieEnabled: navigator.cookieEnabled,
  // };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={Cookies.get("businessName") || ""} />
      </Stack>
      {isLoading ? (
        <Center h={600}>
          <Loading />
        </Center>
      ) : (
        <>
          <Stack py={3} align={"center"}>
            <Heading>
              {getSalutation()} {Cookies.get("userName")}!
            </Heading>
          </Stack>

          {/* <Text>{deviceDetails.platform}</Text> */}
          <HStack
            display={"flex"}
            flexWrap={"wrap"}
            px={2}
            align={"stretch"}
            justifyContent={"center"}
          >
            <Stack
            flex={1}
              maxW={"max-content"}
              px={5}
              py={7}
              borderRadius={"lg"}
              borderWidth={1}
              shadow={"xl"}
            >
              <TableContainer>
                <Table variant="striped" size={"sm"}>
                  <TableCaption>Recent Production</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Product</Th>
                      <Th isNumeric>Output</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {todayProduction.map((item, key) => (
                      <Tr>
                        <Td>{moment(item.date).format("DD MMM")}</Td>
                        <Td>{item.item}</Td>
                        <Td isNumeric>
                          {item.finishedMaterialWeight > 0
                            ? item.finishedMaterialWeight + " KG"
                            : "In Process..."}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>

            <Stack flex={1}>
              <Stat
                boxShadow={"lg"}
                transform={isHovered ? "scale(1.03)" : "scale(1)"}
                transition="all 0.3s ease-in-out"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                p={5}
                textAlign={"center"}
                alignContent={"center"}
              >
                <StatLabel>{t("totalRecievables")}</StatLabel>
                <StatNumber>
                  {totalReceivables !== null
                    ? numberWithCommas(totalReceivables)
                    : "0"}
                </StatNumber>
                {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
              </Stat>
              <Stat
                boxShadow={"lg"}
                // transform={isHovered ? "scale(1.1)" : "scale(1)"}
                transition="all 0.3s ease-in-out"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                p={5}
                textAlign={"center"}
                alignContent={"center"}
              >
                <StatLabel>{t("totalPayables")}</StatLabel>
                <StatNumber>
                  {totalPayables !== null
                    ? numberWithCommas(totalPayables)
                    : "0"}
                </StatNumber>
                {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
              </Stat>
            </Stack>

            <Stack flex={1}>
              <Stat
                boxShadow={"lg"}
                // transform={isHovered ? "scale(1.1)" : "scale(1)"}
                transition="all 0.3s ease-in-out"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                p={5}
                textAlign={"center"}
                alignContent={"center"}
              >
                <StatLabel>This Week Production</StatLabel>
                <StatNumber>
                  {numberWithCommas(thisWeekProduction)} KG
                </StatNumber>
                <StatHelpText>
                  <StatArrow
                    type={productionDifferenceState ? "increase" : "decrease"}
                  />
                  {numberWithCommas(productionDifference)}%
                </StatHelpText>
                {/* <StatHelpText fontFamily={"monospace"} fontSize={"sm"}>
              *this does not include wasted output
              </StatHelpText> */}
              </Stat>
              <Stat
                boxShadow={"lg"}
                // transform={isHovered ? "scale(1.1)" : "scale(1)"}
                transition="all 0.3s ease-in-out"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                p={5}
                textAlign={"center"}
                alignContent={"center"}
              >
                <StatLabel>Last Week Production</StatLabel>
                <StatNumber>
                  {numberWithCommas(lastWeekProduction)} KG
                </StatNumber>
                <StatHelpText>
                  <StatArrow
                    type={
                      productionLasterDifferenceState ? "increase" : "decrease"
                    }
                  />
                  {numberWithCommas(productionLasterDifference)}%
                </StatHelpText>
                {/* <StatHelpText fontFamily={"monospace"} fontSize={"sm"}>
              *this does not include wasted output
              </StatHelpText> */}
              </Stat>
            </Stack>

            <Stack flex={1}>
              <Stat
                boxShadow={"lg"}
                // transform={isHovered ? "scale(1.1)" : "scale(1)"}
                transition="all 0.3s ease-in-out"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                cursor="pointer"
                borderWidth={1}
                borderRadius={10}
                p={5}
                textAlign={"center"}
                alignContent={"center"}
              >
                <StatLabel>Receivable Turnover</StatLabel>
                <StatNumber>{numberWithCommas(receivableRatio)}</StatNumber>
                <StatHelpText>
                  ~{numberWithCommas(365 / receivableRatio)} Days
                </StatHelpText>
                {/* <StatHelpText fontFamily={"monospace"} fontSize={"sm"}>
              *this does not include wasted output
              </StatHelpText> */}
              </Stat>
            </Stack>

            <Stack display={"flex"} flex={1}>
              <CustomerPurchasesPieChart />
            </Stack>
          </HStack>
          {/* <Bar /> */}
          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("transact")}
            </Text>

            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems1.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>

            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("review")}
            </Text>
            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems4.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>
            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("manage")}
            </Text>

            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems2.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>

            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>

          <Stack p={5}>
            <Text fontSize={"x-large"} alignSelf={"flex-start"}>
              {t("reporting")}
            </Text>
            <HStack pb={5} pt={3} flexWrap={"wrap"}>
              {menuItems3.map((items, index) => (
                <Button onClick={() => navigate(items.nav)}>
                  {items.name}
                </Button>
              ))}
            </HStack>
            <Stack px={10}>
              <Divider
                width={"100%"}
                height={1}
                // backgroundColor={useColorModeValue("gray.700", "gray.100")}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Dashboard;
