import React, { useEffect, useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { getHeadersForRequest } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

// Register required chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

type CustomerPurchasesResponse = {
  customerPurchases: { [key: string]: number[] };
};

export default function CustomerPurchasesPieChart() {
  const [chartData, setChartData] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { t } = useCustomTranslation();

  useEffect(() => {
    const fetchCustomerPurchases = async () => {
      try {
        const headers = await getHeadersForRequest();
        const response = await axios.get<CustomerPurchasesResponse>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/monthly/customer-purchases`,
          {
            headers,
          }
        );
        const processedData = processCustomerPurchases(response.data);
        setChartData(processedData);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching customer purchases data:", error);
      }
    };

    fetchCustomerPurchases();
  }, []);

  const processCustomerPurchases = (data: CustomerPurchasesResponse) => {
    const totals: { label: string; total: number }[] = [];

    for (const [customerKey, monthlyData] of Object.entries(data.customerPurchases)) {
      const [, customerName] = customerKey.split(":");
      const total = monthlyData.reduce((sum, value) => sum + value, 0);
      totals.push({ label: customerName, total });
    }

    totals.sort((a, b) => b.total - a.total);

    return {
      labels: totals.map((item) => item.label),
      datasets: [
        {
          label:"Total Purchases",
          data: totals.map((item) => item.total),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
          ], // Optional: Define colors for each slice
        },
      ],
    };
  };

  if (!isLoaded) return <p>{t("Loading...")}</p>;

  return (
    <div style={{ width: "70%", margin: "0 auto" }}>
      {/* <h3>{t("Customer Purchases Pie Chart")}</h3> */}
      {chartData && <Doughnut data={chartData} options={{
            plugins: {
              legend: {
                display: false, // Hide legend (top labels)
              },
              tooltip: {
                enabled: true, // Ensure tooltip is enabled for hover
              },
            },
          }}/>}
    </div>
  );
}
