import Cookies from "js-cookie";
import { taxRates } from "./strings";
import { OrderList } from "./types";

// export function numberWithCommas(x: number) {
//   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export function numberWithCommas(x: number | string): string {
  const num = typeof x === "number" ? x : parseFloat(x); // Ensure `x` is a number
  if (isNaN(num)) {
    throw new TypeError("Input must be a valid number or a numeric string");
  }

  // Check if the number has decimal places
  const hasDecimal = num % 1 !== 0;

  return hasDecimal
    ? num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Keep 2 decimal places if applicable
    : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Otherwise, no decimals
}

export const checkCookies = async () => {
  try {
    const emailAddress = Cookies.get("emailAddress");
    const deviceId = Cookies.get("deviceId");
    const userToken = Cookies.get("userToken");

    if (userToken === null || emailAddress === null || deviceId === null) {
      return 404;
    } else {
      return {
        userToken: userToken,
        emailAddress: emailAddress,
        deviceId: deviceId,
      };
    }
  } catch (error) {
    return 404;
  }
};

export const getHeadersForRequest = async () => {
  try {
    const emailAddress = Cookies.get("emailAddress");
    const deviceId = Cookies.get("deviceId");
    const userToken = Cookies.get("userToken");
    return {
      userToken: userToken,
      emailAddress: emailAddress,
      deviceId: deviceId,
    };
  } catch (error) {
    console.error(error);
  }
};

export function getDescriptionById(id: number) {
  const item = taxRates.find(item => item.id === id);
  return item ? item.description : undefined;
}

export function isFirstOrderType(order: OrderList): order is {
  id: number;
  flavour: string;
  packetNumber: string;
  size: string;
  price: string;
  product: string;
  transactionNumber: number;
  userId: string;
  transactionDate: Date;
  totalAmount: number;
  customerId: number;
  paymentStatus: number;
  paymentDate: Date;
  deliveryStatus: boolean;
  deliveryDate: Date;
  physicalDeliveryNote: boolean;
  deliveryNoteNumber: string;
  transactionType: number;
  supplierId: number;
  invoiceDate: Date;
  isVoid: boolean;
  name: string;
  tin: string;
  phoneNumber: string;
  location: string;
  traCode: string;
  traTime: string;
  QRLink: string;
  traReceiptNumber: string; 
} {
  return 'flavour' in order;
}

export const getCurrentWeekRange = (weeksAgo: number) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Adjusting for Monday as the first day of the week
  const monday = new Date(today);
  monday.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1) - 7 * weeksAgo);
  monday.setHours(0, 0, 0, 0);

  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  sunday.setHours(23, 59, 59, 999);

  return { monday, sunday };
};

