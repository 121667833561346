import { useEffect, useState, useRef } from "react";
import { getHeadersForRequest } from "../utils/functions";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import {
  Avatar,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import moment from "moment";
import Cookies from "js-cookie";
import SignatureCanvas from "react-signature-canvas";

const Settings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [signature, setSignature] = useState<string | null>(null);
  const toast = useToast();
  const sigCanvas = useRef<any>(); // Reference to the signature canvas
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false); // Control modal visibility
  const { colorMode, toggleColorMode } = useColorMode();
  const [image, setImage] = useState<string | null>(
    localStorage.getItem("profilePic")?.toString() || null
  ); // Stores the compressed image as a blob
  const [previewUrl, setPreviewUrl] = useState<string>(
    localStorage.getItem("profilePic")?.toString() || ""
  ); // For displaying the image preview
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Reference for the hidden file input
  const [newPic, setNewPic] = useState<boolean>(false);
  const [syncingDocs, setSyncingDocs] = useState<boolean>(false);

  useEffect(() => {
    console.log("image changed");

    handleImageChanged();
  }, [image]);

  const handleImageChanged = () => {
    if (image !== localStorage.getItem("profilePic")) {
      setNewPic(true);
    } else {
      setNewPic(false);
    }
  };

  // Function to handle file input
  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]; // Get the selected file

  //   if (file) {
  //     // Compress the image using the Resizer library
  //     Resizer.imageFileResizer(
  //       file,
  //       800, // max width
  //       600, // max height
  //       "JPEG", // compress to JPEG format
  //       70, // quality percentage
  //       0, // rotation degree
  //       (uri) => {
  //         // Ensure that `uri` is of type Blob
  //         if (uri instanceof Blob) {
  //           setImage(uri); // Set the Blob state if uri is a Blob
  //           setPreviewUrl(URL.createObjectURL(uri)); // Set the preview URL to show the image
  //         } else {
  //           console.error("Unexpected response from imageFileResizer: ", uri);
  //         }
  //       },
  //       "blob" // Output format (Blob)
  //     );
  //   }
  // };

  // Function to trigger file input click
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Simulate a click on the hidden file input
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Get the selected file

    if (file) {
      // Resize and compress the image using the Resizer library
      Resizer.imageFileResizer(
        file,
        800, // Max width
        600, // Max height
        "JPEG", // Compress to JPEG format
        70, // Quality percentage
        0, // Rotation (optional)
        (resizedImage) => {
          // Handle different return types properly
          if (typeof resizedImage === "string") {
            // Set the resized image as Base64 string
            // console.log(resizedImage);
            setImage(resizedImage);
            setPreviewUrl(resizedImage); // Set preview URL to Base64 string for preview
          } else {
            console.error("Unexpected response type: ", resizedImage);
          }
        },
        "base64" // Output format (Base64 string)
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const headers = await getHeadersForRequest();

    if (!image) {
      alert("Please select an image first!");
      return;
    }

    try {
      // Send the Base64 data as a JSON payload
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/profilePic`,
        { image: image },
        { headers }
      );
      // console.log(response);
      alert("Image uploaded successfully!");
      localStorage.setItem("profilePic", image);
      handleImageChanged();
    } catch (error) {
      console.error("Error uploading image", error);
      alert("Failed to upload image");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSignature();
  }, []);

  const saveSignature = async () => {
    setModalIsLoading(true);
    const headers = await getHeadersForRequest();
    const signatureData = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { signature: signatureData },
        { headers }
      );

      if (response.status === 200) {
        // console.log("Signature saved successfully");
        setIsSignatureModalOpen(false);
        setModalIsLoading(false);
        getSignature();
      } else {
        // console.log("Error saving signature");

        setModalIsLoading(false);
      }
    } catch (error) {
      console.error(error);

      setModalIsLoading(false);
    }
  };

  const getSignature = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/signature`,
        { headers }
      );
      // console.log(response.data.signature);
      if (response.status === 200) {
        setSignature(response.data.signature);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Open the signature modal for customer or staff
  const openSignatureModal = () => {
    setIsSignatureModalOpen(true);
  };

  // Clear signature canvas
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const syncSupplierDocs = async () => {
    const headers = await getHeadersForRequest();
    setSyncingDocs(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/google/manualSyncSupplierDocs`,
        { headers }
      );

      console.log(response);

      if (response.data.success === 1) {
        toast({
          title: "Sync Successful, synced " + response.data.updateLength + " docs",
          duration: 3000,
          status: "success",
        });
      }else{
        toast({
          title: "Sync error, please try again later",
          duration: 3000,
          status: "error",
        });
      }

      setSyncingDocs(false);

    } catch (error) {
      setSyncingDocs(false);
      console.error(error);
      toast({
        title: "Sync error, please try again later",
        duration: 3000,
        status: "error",
      });
    }
  };

  const handleDownloadLogs = async () => {
    const headers = await getHeadersForRequest();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/logs`,
        { headers, responseType: "blob" }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "KOAS.log"; // Adjust the filename
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return (
    <Stack>
      <Stack>
        <Stack position="sticky" top={0} zIndex={1} width="full">
          <Nav pageName={"Settings"} />
        </Stack>
        <Stack px={10} py={10}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              {/* <Heading>
                Signature status: {signature !== null ? "Yes" : "No"}
              </Heading> */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef} // Reference to the hidden input
                style={{ display: "none" }} // Hide the input
              />
              <Stack pb={10}>
                <Center>
                  <Stack>
                    <Avatar
                      onClick={handleClick}
                      size={"2xl"}
                      src={image ? image : "abc"}
                      // src={localStorage.getItem("profilePic")}
                    />
                    {newPic && <Button onClick={handleSubmit}>Upload</Button>}
                  </Stack>
                </Center>
                <Center>
                  <Text fontSize={"2xl"}>{Cookies.get("userName")}</Text>
                </Center>
                <Center>
                  <Text>{Cookies.get("emailAddress")}</Text>
                </Center>
              </Stack>
              <HStack>
                <Button onClick={() => openSignatureModal()}>
                  {signature !== null ? "Update Signature" : "Add Signature"}
                </Button>
                {signature !== null && (
                  <Stack
                    ml={3}
                    bg="white"
                    borderRadius={"10"}
                    borderWidth={1}
                    borderColor={"black"}
                  >
                    <Image h={50} w={150} src={signature} alt="Signature" />
                  </Stack>
                )}
              </HStack>

              <Button
                isLoading={syncingDocs}
                onClick={() => syncSupplierDocs()}
              >
                Sync Google Drive References
              </Button>
              
               <Button
                isLoading={syncingDocs}
                onClick={() => handleDownloadLogs()}
              >
               Download User Logs
              </Button>

              {/* <Button
                onClick={() => {
                  console.log(localStorage.getItem("profilePic"));
                  console.log("");
                  console.log(image);
                }}
              >
                Profile Log
              </Button> */}

              {/* <Stack bg="white" h={250} w={"50%"}>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    // minWidth : 0.5,
                    // width: 0.5,
                    height: 500,
                    className: "sigCanvas",
                  }}
                />
              </Stack> */}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Modal
        isOpen={isSignatureModalOpen}
        onClose={() => setIsSignatureModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Signature</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              borderWidth={1}
              borderColor={colorMode === "light" ? "black" : "white"}
              borderRadius={10}
              bg={"white"}
              w={"100%"}
              // h={250}
            >
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  height: 500,
                  className: "sigCanvas",
                }}
              />
            </Stack>

            <ModalFooter>
              <Button onClick={clearSignature} mr={3}>
                Clear
              </Button>
              <Button isLoading={modalIsLoading} onClick={saveSignature}>
                Save Signature
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default Settings;
