import React from "react";
import Nav from "../components/navbar";
import { Text, Stack, HStack } from "@chakra-ui/react";
import Bar from "../components/analytics/ThisYearBar";
import OldBar from "../components/analytics/LastYearBar";
import OlderBar from "../components/analytics/LasterYearBar";
import CustomerPurchasesChart from "../components/analytics/CustomersPurchase";
import LastYearCustomerPurchasesChart from "../components/analytics/LastYearCustomersPurchase";
import useCustomTranslation from "../hooks/useCustomTranslation";
import CustomerPurchasesPieChart from "../components/analytics/PieTemplate";
import ExpensesThisYearPieChart from "../components/analytics/ExpensesThisYear";
import CustomerPurchasesPieChartLastYear from "../components/analytics/PieTemplateLastYear";
import ExpensesLastYearPieChart from "../components/analytics/ExpensesLastYear";
import ProductSalesData from "../components/analytics/ProductSalesData";

const Analytics: React.FC = () => {
  const { t, changeLanguage } = useCustomTranslation();

  return (
    <>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Analytics"} />
      </Stack>
      <Stack px={10} py={5}>
        <Text>{t("Current year performance")}</Text>
        <Bar />
        <br />
        <Text>{t("Last year performance")}</Text>
        <OldBar />
        <br />
        <Text>{t("Laster year performance")}</Text>
        <OlderBar />
        <br />
        <Text>{t("Current Year Customer Orders")}</Text>
        <CustomerPurchasesChart />
        <br />
        <Text>{t("Last Year Customer Orders")}</Text>
        <LastYearCustomerPurchasesChart />
        <br />
        <HStack px={1} w="100%">
          <Stack w="100%">
            <Text>{t("This Year Customer Orders Pie")}</Text>
            <CustomerPurchasesPieChart />
          </Stack>
          <Stack w="100%">
            <Text>{t("Last Year Customer Orders Pie")}</Text>
            <CustomerPurchasesPieChartLastYear />
          </Stack>
        </HStack>
        <HStack px={1} w="100%">
          <Stack w="100%">
            <Text>{t("Current Expenses")}</Text>
            <ExpensesThisYearPieChart />
          </Stack>
          <Stack w="100%">
            <Text>{t("Last Year Expenses")}</Text>
            <ExpensesLastYearPieChart />
          </Stack>
        </HStack>

        <ProductSalesData/>
      </Stack>
    </>
  );
};

export default Analytics;
