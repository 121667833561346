import { Button, HStack, Input, Select, Stack, Text, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useRef } from "react";
import { getHeadersForRequest } from "../utils/functions";
import { CustomerStatus, CustomerTypes } from "../utils/strings";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

// Fix default Leaflet icon paths
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { useNavigate, useParams } from "react-router-dom";
import Nav from "./navbar";
// import PlacesAutocomplete from "./PlacesAutoComplete";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Google Maps API Key from environment
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// Define which libraries to load (we need "places" for autocomplete)
const libraries: "places"[] = ["places"];

// A component to pan the map when the center changes
function Recenter({ center }: { center: [number, number] }) {
  const map = useMap();
  map.setView(center);
  return null;
}

// Autocomplete component using the Google Places API
const PlacesAutocomplete = ({
  onPlaceSelected,
}: {
  onPlaceSelected: (lat: number, lng: number, address: string) => void;
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (
        place.geometry &&
        place.geometry.location &&
        place.formatted_address
      ) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const address = place.formatted_address;
        onPlaceSelected(lat, lng, address);
      }
    }
  };

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <input
        type="text"
        placeholder="Search address..."
        style={{ width: "100%", padding: "0.5rem", marginBottom: "1rem" }}
      />
    </Autocomplete>
  );
};

type AddCustomerCardProps = {
  id?: string;
  name?: string;
  tin?: string;
  phoneNumber?: string;
  location?: string;
  isVoid?: boolean;
  type?: number;
  isEdit?: boolean;
  latitude?: number;
  longitude?: number;
  isModal?: boolean;
};

export const AddCustomer: React.FC<AddCustomerCardProps> = ({isModal}) => {

  const { data } = useParams<{ data?: any }>();
  let parsedData : any;
  if(data){
    parsedData = JSON.parse(decodeURIComponent(data));
  }

  const [name1, setName] = useState<string>(parsedData?.customer?.name || "");
  const [tin1, setTin] = useState<string>(parsedData?.customer?.tin || "");
  const [phoneNumber1, setPhoneNumber] = useState<string>(parsedData?.customer?.phoneNumber || "");
  const [location1, setLocation] = useState<string>(parsedData?.customer?.location || "");
  const [isVoid1, setisVoid] = useState<boolean>(parsedData?.customer?.isVoid || false);
  const [type1, setType] = useState<number>(parsedData?.customer?.category || 0);
  const [latitude1, setLatitude] = useState<number>(parseFloat(parsedData?.customer?.latitude) || -3.3710);
  const [longitude1, setLongitude] = useState<number>(parseFloat(parsedData?.customer?.longitude) || 36.7327);
  const [saveLocation, setSaveLocation] = useState<boolean>(!isModal ? parsedData?.customer?.latitude !== null && parsedData?.customer?.longitude !== null ? true : false : false);
  const [status1, setStatus] = useState<number>(parsedData?.customer?.status || 1);

  const navigate = useNavigate();

  const [selectedTypeString, setSelectedTypeString] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t, changeLanguage } = useCustomTranslation();
  const toast = useToast();

  const [mapCenter, setMapCenter] = useState<[number, number]>([
    latitude1,
    longitude1,
  ]);

  // When a place is selected via autocomplete, update the map & marker
  const handlePlaceSelected = (lat: number, lng: number, address: string) => {
    setLatitude(lat);
    setLongitude(lng);
    setMapCenter([lat, lng]);
    // Optionally, update the form fields with the address info
    if(location1 === ""){
      setLocation(address);
    }
  };

  const [mapKey, setMapKey] = useState(0);

  const reloadMap = () => {
    setMapKey((prevKey) => prevKey + 1);
  };

  const handleEdit = async () => {
    setIsLoading(true);

    // console.log({id: parsedData?.customer?.id,
    //   name: name1,
    //   tin: tin1 === "" ? null : tin1,
    //   phoneNumber: phoneNumber1,
    //   location: location1,
    //   latitude: latitude1,
    //   longitude: longitude1,
    //   category: type1,
    //   isVoid: isVoid1,})
    //   setIsLoading(false);
    //   return
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "warning",
      });
      setIsLoading(false);
      return;

    } 
    // if (latitude1 !== null && !saveLocation){
    //   toast({
    //     title: t("Location will be removed!"),
    //     duration: 3000,
    //     status: "warning",
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    else {
      const headers = await getHeadersForRequest();

      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/customers/update`,
          {
            id: parsedData?.customer?.id,
            name: name1,
            tin: tin1 === "" ? null : tin1,
            phoneNumber: phoneNumber1,
            location: location1,
            latitude: saveLocation ? latitude1 : null,
            longitude: saveLocation ? longitude1 : null,
            category: type1,
            isVoid: isVoid1,
            status: status1
          },
          { headers }
        )
        .then((response) => {
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    // console.log({
    //   name: name1,
    //   tin: tin1 === "" ? null : tin1,
    //   phoneNumber: phoneNumber1,
    //   location: location1,
    //   category: type1,
    //   latitude: latitude1,
    //   longitude: longitude1,
    // });

    // return;
    if (name1.trim() === "") {
      toast({
        title: t("Name cannot be empty"),
        duration: 3000,
        status: "error",
      });
      setIsLoading(false);
    } else {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/customers/save`,
          {
            name: name1,
            tin: tin1 === "" ? null : tin1,
            phoneNumber: phoneNumber1,
            location: location1,
            category: type1,
            latitude: latitude1,
            longitude: longitude1,
            status: status1
          },
          { headers }
        )
        .then((response) => {
          // console.log(response.data);
          toast({
            title: t("Details updated successfully"),
            duration: 3000,
            status: "success",
          });
          setName("");
          setTin("");
          setPhoneNumber("");
          setLocation("");
          setIsLoading(false);
          navigate(-1);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast({
            title: t("Error saving"),
            duration: 3000,
            status: "error",
          });
        });
    }
  };
  return (
    <Stack>
      <Stack>
        {!isModal && (
          <Stack position="sticky" top={0} zIndex={1} width="full">
            <Nav
              pageName={
                parsedData?.isEdit ? "Edit Customer Details" : "Add Customer"
              }
            />
          </Stack>
        )}
        <Stack px={10} py={10}>
          <Stack>
            <Text>{t("Name")}</Text>
            <Input
              defaultValue={name1}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("Enter name")}
              autoCapitalize={"words"}
            />

            <br />
            <br />

            <Text>{t("Customer ID type")}</Text>
            <Select
              minW={"300"}
              flex={1}
              defaultValue={type1}
              onChange={(e) => {
                const values = e.target.value.split("-");
                setType(parseInt(values[0]));
                setSelectedTypeString(values[1]);
              }}
            >
              <option value={0}>{t("Pick ID Type")}</option>
              {CustomerTypes.map((types) => (
                <option value={types.id}>
                  {types.description}
                </option>
              ))}
            </Select>

            {type1 !== 6 && type1 !== 0 && (
              <>
                <Text>{selectedTypeString}</Text>
                <Input
                  defaultValue={tin1}
                  onChange={(e) => setTin(e.target.value)}
                  placeholder={t("Enter") + selectedTypeString}
                  inputMode={"numeric"}
                />
              </>
            )}

            <br />
            <br />

            <Text>{t("Phone Number")}</Text>
            <Input
              defaultValue={phoneNumber1}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={t("Enter phone number")}
              inputMode="tel"
            />

            <br />
            <br />

            <Text>{t("Customer Status")}</Text>
            <Select
              minW={"300"}
              flex={1}
              defaultValue={status1}
              onChange={(e) => {
                // const values = e.target.value.split("-");
                // console.log(e.target.value);
                setStatus(parseInt(e.target.value));
              }}
            >
              {/* <option value={1}>{t("")}</option> */}
              {CustomerStatus.map((status) => (
                <option value={status.id}>
                  {status.description}
                </option>
              ))}
            </Select>

            <br />
            <br />

            <Button
              onClick={() => {
                // console.log({ parsedData });
                console.log(location1)
              }}
            >
              SHOW DETAILS
            </Button>

            <HStack justifyContent={"space-between"}>
              <Text>{t("Location")}</Text>
              {!saveLocation && latitude1 && longitude1 && (
                <Button
                  onClick={() => {
                    if (isModal) {
                      toast({
                        title: t("Location can not be added from this page"),
                        duration: 3000,
                        status: "info",
                      });
                      return;
                    }
                    setSaveLocation(true);
                  }}
                >
                  ADD LOCATION
                </Button>
              )}

              {saveLocation && (
                <Button
                  onClick={() => {
                    setSaveLocation(false);
                  }}
                >
                  REMOVE LOCATION
                </Button>
              )}
            </HStack>
            <Input
            defaultValue={location1}
            onChange={(e) => setLocation(e.target.value)}
            placeholder={t("Enter location")}
          />
          <Text>**General area, edit this if the auto populated value is incorrect</Text>

            {/* <LoadScript
            googleMapsApiKey={GOOGLE_MAPS_API_KEY || ""}
            libraries={libraries}
          > */}

            {/* <MapLocationPicker/> */}
            {saveLocation && (
              <div
                style={{ width: "100%", maxWidth: "800px", margin: "0 auto" }}
              >
                {/* Google Places Autocomplete Input */}
                <PlacesAutocomplete
                  onPlaceSelected={handlePlaceSelected}
                  key={`autocomplete-${Date.now()}`}
                />

                {/* React-Leaflet Map */}
                <MapContainer
                  center={mapCenter}
                  zoom={30}
                  style={{ width: "100%", height: "400px" }}
                >
                  <TileLayer
                    // attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  />
                  <Recenter center={mapCenter} />
                  <Marker
                    position={[latitude1, longitude1]}
                    draggable={true}
                    eventHandlers={{
                      dragend: (e) => {
                        const marker = e.target;
                        const newPos = marker.getLatLng();
                        // setSelectedLocation({ lat: newPos.lat, lng: newPos.lng });
                        handlePlaceSelected(
                          newPos.lat,
                          newPos.lng,
                          newPos.address
                        );
                        setLatitude(newPos.lat);
                        setLongitude(newPos.lng);
                      },
                    }}
                  >
                    <Popup>
                      Selected Location: {latitude1.toFixed(4)},{" "}
                      {longitude1.toFixed(4)}
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            )}
            {/* </LoadScript> */}

            <br />
            <br />

            <Button
              onClick={parsedData?.isEdit ? handleEdit : handleSave}
              isLoading={isLoading}
            >
              <Text>{parsedData?.isEdit ? t("Update") : t("Save")}</Text>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
