// Define arrays for each dropdown
export const PricesValues = [
  "800",
  "1500",
  "1600",
  "1700",
  "2500",
  "2600",
  "2700",
  "2800",
  "3200",
  "3400",
  "5000",
  "5200",
  "5400",
  "5600",
  "6400",
];
export const PacketSizesValues = ["50", "80", "90", "100", "180", "200", "360"];
export const ProductsValues = [
  "Potato Crisps",
  "Potato Sticks",
  "Banana Crisps",
  "Magimbi Crisps",
  "Cassava Crisps",
  "Chevro",
  "Tambi",
  "Cashewnuts",
  "Tanzanian Bhujia",
  "Peanuts",
  "Mumra"
];
export const FlavourValues = [
  "Plain",
  "Chilli",
  "BBQ",
  "Cheese",
  "Cheese N Onion",
  "Masala",
  "Chilli Cheese",
  "Salt N Vinegar",
];

export const taxRates = [
  {
    id: 1,
    description: "18%",
  },
  {
    id: 2,
    description: "(0%) Special Rate",
  },
  {
    id: 3,
    description: "(0%) Zero Rate",
  },
  {
    id: 4,
    description: "(0%) Special Relief",
  },
  {
    id: 5,
    description: "(0%) Exempt",
  },
];

export const CustomerTypes = [
  { id: 1, description: "TIN" },
  { id: 2, description: "Driving License" },
  { id: 3, description: "Voters Number" },
  { id: 4, description: "Passport" },
  { id: 5, description: "NID (National Identity)" },
  { id: 6, description: "NIL (No ID)" },
];

export const CustomerStatus = [
  { id: 1, description: "Customer Currently Active" },
  { id: 2, description: "Potential, not approached" },
  { id: 3, description: "Potential, approached, awaiting acknowledgement" },
  { id: 4, description: "Potential, rejected" },
  { id: 5, description: "Customer Account Closed" }
];
