import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Input,
  Select,
  Text,
  Stack,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CheckboxGroup,
  Checkbox,
  useToast,
  ModalOverlay,
  IconButton,
  Center,
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
} from "@chakra-ui/react";

import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import Cookies from "js-cookie";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import {
  AccountList,
  CustomerList,
  PurchaseListItem,
  SupplierList,
} from "../utils/types";
import {
  getAccountsList,
  getCustomerList,
  getSupplierList,
} from "../utils/API_calls";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { Loading } from "../components/Loading";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";
// import Nav from "../components/navbar";

const Form1 = () => {
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("0");

  const [invoiceRef, setInvoiceRef] = useState<string>("");
  const [invoiceData, setInvoiceData] = useState<any[]>([]);

  const { t, changeLanguage } = useCustomTranslation();

  const toast = useToast();

  // const retrieveAccounts = async () => {
  //   setModalIsLoading(true);
  //   const accounts = await getAccountsList();
  //   if (accounts.success === 1) {
  //     setAccounts(accounts.data);
  //   } else {
  //     setAccounts([]);
  //   }
  //   setModalIsLoading(false);
  // };


  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceDate(e.target.valueAsDate || new Date());
  };

  const handleSaveData = async () => {
    const email = await Cookies.get("emailAddress");
    const data = {
      customerId: invoiceData[0].customerId,
      paymentStatus: false,
      paymentDate: invoiceDate,
      userId: email,
      deliveryStatus: true,
      deliveryDate: invoiceDate,
      transactionDate: new Date(),
      physicalDeliveryNote: false,
      totalAmount: totalCreditAmount,
      items: creditItems,
      transactionType: 5,
      invoiceDate: invoiceDate,
      deliveryNoteNumber: "",
      account: 0,
      refInvoice: invoiceRef,
    };

    setIsLoading(true);

    try {
      const headers = await getHeadersForRequest();
      const response = await axios.post(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/save/creditnote`,
        data,
        { headers }
      );
      console.log(response.data);
      setIsLoading(false);
      toast({
        title: `Transaction ${response.data.transactionId} Details Saved!`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIndexState(0);
      setCreditItems([]);
      setInvoiceData([]);
      setInvoiceRef("");
    } catch (error) {
      console.error(error);
      toast({
        title: `${error}, please try again later`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const getOrderDetails = async () => {
    setIsLoading(true);
    const oldHeaders = await getHeadersForRequest();
    const newHeaders = {
      orderid: parseInt(invoiceRef),
    };
    const headers = { ...oldHeaders, ...newHeaders };

    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/orderdetails`,
        { headers }
      );

      setInvoiceData(response.data);
      console.log("data " + JSON.stringify(response.data));
      setIsLoading(false);
      goToNextStep();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const steps = [
    { title: "Invoice", description: "Select Invoice" },
    { title: "Items", description: "Select Items" },
    { title: "Confirm", description: "Confirm Items to Credit" },
  ];

  const [creditItems, setCreditItems] = useState<any[]>([]);
  const [indexState, setIndexState] = useState<number>(0);

  const goToNextStep = () => {
    setIndexState((prev) => {
      const newIndex = prev < steps.length - 1 ? prev + 1 : prev;
      console.log("Next Step Index (before updating state):", newIndex);
      return newIndex;
    });
  };

  const goToPrevStep = () => {
    setIndexState((prev) => {
      const newIndex = prev > 0 ? prev - 1 : prev;
      console.log("Previous Step Index (before updating state):", newIndex);
      return newIndex;
    });
  };

  const getItemKey = (item: any) =>
    `${item.product}-${item.flavour}-${item.size}`;

  const handleCheckboxChange = (item: any) => {
    const itemKey = getItemKey(item);

    setCreditItems(
      (prev) =>
        prev.some((creditItem) => getItemKey(creditItem) === itemKey)
          ? prev.filter((creditItem) => getItemKey(creditItem) !== itemKey) // Remove if unchecked
          : [...prev, item] // Add if checked
    );
  };

  const handleInputChange = (
    itemKey: string,
    field: "packetNumber" | "price",
    value: any
  ) => {
    setCreditItems((prev) =>
      prev.map((creditItem) =>
        getItemKey(creditItem) === itemKey
          ? { ...creditItem, [field]: value }
          : creditItem
      )
    );
  };

  const totalCreditAmount = creditItems.reduce((total, item) => total + (item.price * item.packetNumber), 0);

  return (
    <Stack>
      <Stepper size="lg" colorScheme="blue" index={indexState} mt="2%" mb="5%">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>

      {/* <Button onClick={()=> goToNextStep()}>Next</Button>
      <Button onClick={()=> goToPrevStep()}>Previous</Button> */}

      {indexState === 0 && (
        <>
          <Stack px={5} alignItems={"center"} pb={10}>
            <Text color="grey" fontSize="lg" fontFamily="">
              Reference Invoice
            </Text>

            <Stack width={300}>
              <Input
                fontSize="md"
                pl={5}
                borderRadius={30}
                defaultValue={invoiceRef}
                inputMode="numeric"
                onChange={(e) => setInvoiceRef(e.target.value)}
                value={invoiceRef}
                borderWidth={3}
              />
            </Stack>
          </Stack>
          <Stack alignItems={"center"}>
            <Button
              variant={"solid"}
              onClick={() => getOrderDetails()}
              borderRadius={20}
              width={200}
              colorScheme="blue"
              isLoading={isLoading}
              loadingText={"Retrieving"}
            >
              <Text>Retrieve</Text>
            </Button>
          </Stack>
        </>
      )}

      {indexState === 1 && (
        <>
          <HStack justifyContent={"space-between"} mb="5%">
            <Stack>
              <Text fontSize={"large"}>
                Invoice: {invoiceData[0].transactionNumber}
              </Text>
              <Text fontSize={"large"}>
                Del#: {invoiceData[0].deliveryNoteNumber}
              </Text>
            </Stack>

            <Stack textAlign={"right"}>
              <Text fontSize={"large"}>{invoiceData[0].name}</Text>
              <Text fontSize={"large"}>
                {moment(invoiceData[0].invoiceDate).format("DD MMM YYYY")}
              </Text>
            </Stack>
          </HStack>

          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                {" "}
                Number of Items: {invoiceData.length}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>QTY</Th>
                  <Th>DESCRIPTION</Th>
                  <Th isNumeric>PRICE</Th>
                  <Th isNumeric>AMOUNT</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoiceData.map((item) => {
                  const itemKey = getItemKey(item);
                  const isSelected = creditItems.some(
                    (creditItem) => getItemKey(creditItem) === itemKey
                  );
                  const selectedItem = creditItems.find(
                    (creditItem) => getItemKey(creditItem) === itemKey
                  );

                  return (
                    <Tr key={itemKey}>
                      <Td>
                        <Checkbox
                          isChecked={isSelected}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </Td>
                      <Td>
                        {isSelected ? (
                          <Input
                            type="number"
                            value={selectedItem?.packetNumber || ""}
                            onChange={(e) =>
                              handleInputChange(
                                itemKey,
                                "packetNumber",
                                parseInt(e.target.value)
                              )
                            }
                            width="80px"
                          />
                        ) : (
                          item.packetNumber
                        )}
                      </Td>
                      <Td>
                        {item.product} - {item.flavour} {item.size}gms
                      </Td>
                      <Td isNumeric>
                        {isSelected ? (
                          <Input
                            type="number"
                            value={selectedItem?.price || ""}
                            onChange={(e) =>
                              handleInputChange(
                                itemKey,
                                "price",
                                parseFloat(e.target.value)
                              )
                            }
                            width="80px"
                          />
                        ) : (
                          numberWithCommas(parseInt(item?.price))
                        )}
                      </Td>
                      <Td isNumeric>
                        {numberWithCommas(
                          parseInt(selectedItem?.price || item.price) *
                            (selectedItem?.packetNumber || item.packetNumber)
                        )}
                      </Td>
                    </Tr>
                  );
                })}
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td isNumeric>
                    TOTAL :{"  "}
                    {numberWithCommas(parseInt(invoiceData[0].totalAmount))}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Stack
            flex={1}
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={10}
            py={5}
          >
            <Button colorScheme="red" onClick={() => goToPrevStep()}>
              BACK
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                if (creditItems.length > 0) {
                  goToNextStep();
                } else {
                  toast({
                    title: "Please select atleast 1 item",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              NEXT
            </Button>
          </Stack>
        </>
      )}

      {indexState === 2 && (
        <>
          <HStack justifyContent={"space-between"} mb="5%">
            <Stack>
              <Text fontSize={"large"}>
                Ref Invoice: {invoiceData[0].transactionNumber}
              </Text>
              <Text fontSize={"large"}>
                Ref Del#: {invoiceData[0].deliveryNoteNumber}
              </Text>
            </Stack>

            <Stack textAlign={"right"}>
              <Text fontSize={"large"}>{invoiceData[0].name}</Text>
              <Input
                placeholder="Invoice Date"
                type="date"
                value={invoiceDate.toISOString().substring(0, 10)}
                onChange={handleDateChange}
              />
            </Stack>
          </HStack>
          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                {" "}
                Number of Items: {creditItems.length}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>QTY</Th>
                  <Th>DESCRIPTION</Th>
                  <Th isNumeric>PRICE</Th>
                  <Th isNumeric>AMOUNT</Th>
                </Tr>
              </Thead>
              <Tbody>
                {creditItems.map((items, key) => (
                  <Tr key={key}>
                    <Td>
                      <Checkbox
                        disabled
                        isChecked={creditItems.some(
                          (creditItem) =>
                            getItemKey(creditItem) === getItemKey(items)
                        )}
                        onChange={() => handleCheckboxChange(items)}
                      />
                    </Td>
                    <Td>{items?.packetNumber}</Td>
                    <Td>
                      {items?.product} - {items.flavour} {items.size}gms
                    </Td>
                    <Td isNumeric>
                      {numberWithCommas(parseInt(items?.price))}
                    </Td>
                    <Td isNumeric>
                      {numberWithCommas(
                        parseInt(items.price) * items.packetNumber
                      )}
                    </Td>
                  </Tr>
                ))}
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td isNumeric>
                    TOTAL :{"  "}
                    {numberWithCommas(totalCreditAmount)}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Stack
            flex={1}
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={10}
            py={5}
          >
            <Button colorScheme="red" onClick={() => goToPrevStep()}>
              BACK
            </Button>

            <Button
            isLoading={isLoading}
            loadingText={"SAVING..."}
              colorScheme="green"
              onClick={() => {
                handleSaveData();
              }}
            >
              SAVE
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default function CreditNote() {
  const navigate = useNavigate();
  return (
    <Center h="100dvh">
      {/* <Nav /> */}

      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        // maxWidth={"100%"}
        p={6}
        minW={"70%"}
        m="10px auto"
        as="form"
      >
        <HStack justifyContent={"space-between"} alignItems={"baseline"}>
          <IconButton
            size="md"
            fontSize="lg"
            variant="ghost"
            color="current"
            marginLeft="2"
            onClick={() => navigate("/dashboard")}
            icon={<FaAngleLeft />}
            aria-label={`Go to home`}
          />
          <Heading>Credit Note</Heading>
          <Stack alignItems={"flex-end"}>
            <ColorModeSwitcher mb={2} />
          </Stack>
        </HStack>
        <Form1 />
      </Box>
    </Center>
  );
}
