import { useEffect, useState } from "react";
import { AccountList, ExpensesList } from "../utils/types";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getHeadersForRequest } from "../utils/functions";
import axios from "axios";
import { Loading } from "../components/Loading";
import { TransactionCard } from "../components/TransactionCard";
import { FaTrash } from "react-icons/fa";
import Nav from "../components/navbar";
import { getAccountsList } from "../utils/API_calls";
import useCustomTranslation from "../hooks/useCustomTranslation";

const RecentExpensesScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<ExpensesList[]>([]);
  const navigate = useNavigate();
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [refInvoice, setRefInvoice] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string>("0");
  const [
    selectedTransactionPaymentStatus,
    setSelectedTransactionPaymentStatus,
  ] = useState<boolean>(false);
  const toast = useToast();
  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    setIsLoading(true);
    getAllTransactions();
  }, []);

  const navigateToOrderDetails = (orderId: string) => {
    navigate(`/ExpenseDetails/${orderId}`);
  };

  const getAllTransactions = async () => {
    try {
      const today = new Date();
      today.setDate(today.getDate() - 90);
      const startDate = today;
      const endDate = new Date();

      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      const headers = { ...oldHeaders, ...newHeaders };

      const response = await axios.get<ExpensesList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll/expenses`,
        {
          headers,
        }
      );
      // console.log(response.data);
      const filteredOrdersPaymentPending = await response.data.filter(
        (transaction) => transaction.isVoid === false
      );

      setTransactions(
        filteredOrdersPaymentPending.sort(
          (a, b) =>
            new Date(b.invoiceDate).getTime() -
            new Date(a.invoiceDate).getTime()
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const groupedTransactions: {
    date: string;
    transactions: ExpensesList[];
  }[] = [];

  // Sort the groupedTransactions array in descending order of transaction date
  groupedTransactions.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateB - dateA;
  });

  for (let transaction of transactions) {
    const date = new Date(transaction.invoiceDate).toLocaleDateString();
    const existingGroup = groupedTransactions.find(
      (group) => group.date === date
    );
    if (existingGroup) {
      existingGroup.transactions.push(transaction);
    } else {
      groupedTransactions.push({ date, transactions: [transaction] });
    }
  }

  const handleTransactionClick = async (transaction: ExpensesList) => {
    setShowDetailsModal(true);
    if (!transaction.paymentStatus) {
      await retrieveAccounts();
    }
    let delivered = transaction.deliveryStatus ? "delivered" : "";
    let paid = transaction.paymentStatus ? "paid" : "";
    let physical = transaction.physicalDeliveryNote ? "physical" : "";
    let tra = transaction.traReceiptNumber && transaction.traTime ? "tra" : "";
    let newGroupValues = [delivered, paid, physical, tra];

    setGroupValues(newGroupValues);
    setDeliveryNoteNumber(transaction.deliveryNoteNumber);
    setRefInvoice(transaction.refInvoice);
    setTransactionId(transaction.id);
    setTraReceiptNumber(transaction.traReceiptNumber);
    setTraReceiptTime(transaction.traTime);
    setSelectedTransactionPaymentStatus(transaction.paymentStatus);
    setSelectedAccount(transaction.account);
  };

  const updateTransactionDetails = async () => {
    if (groupValues.includes("paid") && selectedAccount === "0") {
      toast({
        title: "Please select an account",
        duration: 3000,
        status: "warning",
      });
      return;
    }
    try {
      const data = {
        paymentStatus: groupValues.includes("paid"),
        paymentDate: groupValues.includes("paid") ? new Date() : null,
        transactionId: transactionId,
        deliveryStatus: groupValues.includes("delivered"),
        deliveryDate: groupValues.includes("delivered") ? new Date() : null,
        invoiceDate: new Date(),
        physicalDeliveryNote: groupValues.includes("physical"),
        deliveryNoteNumber: groupValues.includes("physical")
          ? deliveryNoteNumber
          : "",
        traReceiptNumber: traReceiptNumber,
        traReceiptTime: traReceiptTime,
        account: selectedAccount,
        refInvoice: refInvoice,
      };

      // if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
      //   toast({ title: "Invalid Delivery Note Number" });
      //   return;
      // }

      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/update`,
          {
            data,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Transaction details updated successfully",
            duration: 3000,
            status: "success",
          });
          setShowDetailsModal(false);
          getAllTransactions();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error updating transaction",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {}
  };

  const voidTransaction = async () => {
    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/delete`,
          {
            transactionId: transactionId,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Transaction VOID successful",
            duration: 3000,
            status: "success",
          });
          setShowDetailsModal(false);
          setShowConfirmationModal(false);
          setIsLoading(false);
          getAllTransactions();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error, please try again later",
            duration: 3000,
            status: "error",
          });
          setShowConfirmationModal(false);
          setIsLoading(false);
        });
    } catch (error) {
      setShowConfirmationModal(false);
      setIsLoading(false);
      console.error(error);
    }
  };

  const retrieveAccounts = async () => {
    setModalIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setModalIsLoading(false);
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Expense History"} />
      </Stack>
      <Stack flex={1}>
        <Stack pt={2} pb={10} px={3}>
          {isLoading ? (
            <Center h={600}>
              <Loading />
            </Center>
          ) : (
            <Stack>
              <Text>Record count: {groupedTransactions.length}</Text>
              {groupedTransactions.map((groupedTransaction, index) => (
                <Stack key={index}>
                  <Heading size="md" my={2}></Heading>
                  {groupedTransaction.transactions.map((transaction, index) => (
                    <>
                      <Stack
                        px={5}
                        key={index}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (transaction.isVoid) {
                            toast({
                              title: "Cannot edit a VOID receipt",
                            });
                          } else {
                            handleTransactionClick(transaction);
                          }
                        }}
                        onClick={() =>
                          navigateToOrderDetails(transaction.transactionNumber)
                        }
                      >
                        <TransactionCard transaction={transaction} />
                      </Stack>
                    </>
                  ))}
                </Stack>
              ))}
              {groupedTransactions.length < 1 && (
                <Stack alignItems={"center"} justifyContent={"center"} h={400}>
                  <Text fontSize={20} textAlign={"center"}>
                    There are no Transactions to show
                  </Text>
                  <Text fontSize={20} textAlign={"center"}>
                    Go to the Sales screen to record your first transaction!
                  </Text>
                </Stack>
              )}
            </Stack>
          )}

          {/* TRANSACTION DETAILS MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Transaction Details</ModalHeader>
              <ModalBody>
                {modalIsLoading ? (
                  <Center>
                    <Loading />
                  </Center>
                ) : (
                  <>
                    <HStack justifyContent={"space-between"}>
                      <Text>Order details:</Text>

                      <IconButton
                        size="md"
                        fontSize="lg"
                        variant="ghost"
                        color="current"
                        marginLeft="2"
                        onClick={() => setShowConfirmationModal(true)}
                        icon={<FaTrash color="red" />}
                        aria-label={`Go to home`}
                      />
                    </HStack>

                    <Stack>
                      <CheckboxGroup
                        onChange={(values: string[]) => setGroupValues(values)}
                        value={groupValues}
                      >
                        <Checkbox
                          disabled={selectedTransactionPaymentStatus}
                          value="paid"
                          my={2}
                        >
                          Paid
                        </Checkbox>
                        <Checkbox value="delivered" my={2}>
                          Delivered
                        </Checkbox>
                        <Checkbox value="physical" my={2}>
                          Supplier Reference Docs
                        </Checkbox>
                        {/* <Checkbox value="tra" my={2}>
                          TRA Receipt
                        </Checkbox> */}
                      </CheckboxGroup>
                    </Stack>

                    <br />
                    {groupValues.includes("paid") &&
                      !selectedTransactionPaymentStatus && (
                        <>
                          <Text>Pick the account used for payment </Text>
                          <Select
                            onChange={(e) => setSelectedAccount(e.target.value)}
                          >
                            <option value={""}>{t("Pick Account")}</option>
                            {accounts.map((account) => (
                              <option
                                // label={customer.name}
                                value={account.id.toString()}
                              >
                                {account.name}
                              </option>
                            ))}
                          </Select>
                        </>
                      )}
                    <br />

                    <Stack>
                      {groupValues.includes("physical") && (
                         <>
                         <Input
                           fontSize="md"
                           pl={5}
                           backgroundColor="transparent"
                           variant={"underlined"}
                           defaultValue={refInvoice}
                           onChange={(e) => setRefInvoice(e.target.value)}
                           placeholder={"Enter reference invoice number"}
                         />
       
                         <Input
                           fontSize="md"
                           pl={5}
                           backgroundColor="transparent"
                           variant={"underlined"}
                           defaultValue={deliveryNoteNumber}
                           onChange={(e) => setDeliveryNoteNumber(e.target.value)}
                           placeholder={"Enter delivery note number"}
                         />
                       </>
                      )}
                    </Stack>
                    {/* <Stack>
                      {groupValues.includes("tra") && (
                        <>
                          <Input
                            fontSize="md"
                            pl={5}
                            inputMode="numeric"
                            backgroundColor="transparent"
                            variant={"underlined"}
                            defaultValue={traReceiptNumber}
                            onChange={(e) =>
                              setTraReceiptNumber(e.target.value)
                            }
                            placeholder={"Enter TRA Receipt Number"}
                          />

                          <Input
                            fontSize="md"
                            pl={5}
                            inputMode="numeric"
                            backgroundColor="transparent"
                            variant={"underlined"}
                            defaultValue={traReceiptTime}
                            onChange={(e) => setTraReceiptTime(e.target.value)}
                            placeholder={"Enter Receipt Time"}
                          />
                        </>
                      )}
                    </Stack> */}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowDetailsModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    // onClick={() => {
                    //   updateTransactionDetails();
                    // }}
                  >
                    Update
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* CONFIRMATION MODAL */}
          <Modal
            isCentered
            closeOnOverlayClick={false}
            isOpen={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
          >
            <ModalContent maxWidth="350">
              <ModalCloseButton />
              <ModalHeader>Alert</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text textAlign={"center"}>
                    Are you sure you want to VOID this receipt?
                  </Text>
                  <Text textAlign={"center"}>THIS ACTION IS IRREVERSIBLE!</Text>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant="ghost"
                    colorScheme="blueGray"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    bg={"red"}
                    onClick={() => {
                      voidTransaction();
                    }}
                  >
                    Yes
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecentExpensesScreen;
