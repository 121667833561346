import { ReactNode, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ButtonGroup,
  ModalFooter,
  useBreakpointValue,
  Input,
} from "@chakra-ui/react";
import { ArrowBackIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import logo from "../resources/images/logo.jpg";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import axios from "axios";
import useCustomTranslation from "../hooks/useCustomTranslation";
import moment from "moment";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);

type navbarProps = {
  pageName: string;
};

const Nav: React.FC<navbarProps> = ({ pageName }) => {
  const today = new Date();

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showDatePickerModal, setShowDatePickerModal] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(today.toISOString());
  const [endDate, setEndDate] = useState<string>(today.toISOString());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();

  const { t, changeLanguage } = useCustomTranslation();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const headers = await getHeadersForRequest();
      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/auth/logout`,
        {
          headers,
        }
      );
      console.log(response.data);
      if (response.data.errorCode === 0) {
        setIsLoading(false);
        Cookies.remove("userName");
        Cookies.remove("userToken");
        Cookies.remove("emailAddress");
        Cookies.remove("deviceId");
        localStorage.clear();
        toast({ title: "Successfully Logged Out", status: "success" });
        setShowConfirmationModal(false);
        navigate("/login");
      } else {
        toast({ title: "Failed to logout", status: "error" });
        setIsLoading(false);
        setShowConfirmationModal(false);
      }
    } catch (error) {
      console.error("Error fetching customer purchases data:", error);
      toast({ title: "Failed to logout", status: "error" });
      setIsLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const getFinancialStatementData = async () => {
    try {
      setIsLoading(true);

      const oldHeaders = await getHeadersForRequest();
      const newHeaders = {
        startdate: moment(startDate).format("YYYY-MM-DD"),
        enddate: moment(endDate).format("YYYY-MM-DD"),
      };
      const headers = { ...oldHeaders, ...newHeaders };

      const response = await axios.get(
        `${process.env.REACT_APP_TEST_API_URL}/analytics/incomeStatement`,
        {
          headers,
        }
      );

      // console.log(response.data.IncomeStatement);
      // setCategoriesList(response.data);
      // setIncomeStatementData(response.data.IncomeStatement);
      // if (response.data.IncomeStatement.length > 0){
      generateIncomeStatementPDF(response.data.IncomeStatement);
      // }
      // else{
      // toast({ title: "Failed to generate income statement, no data received for selected dates", status: "error" });
      // }
      // setTrialBalanceData(response.data.TrialBalance);
      // console.log(response.data.IncomeStatement);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const generateIncomeStatementPDF = async (incomeStatement: any) => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 700]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Title
    const title = "Income Statement";
    page.drawText(title, {
      x: 200,
      y: height - 50,
      size: 24,
      font: font,
      color: rgb(0, 0.2, 0.6),
    });

    const dates =
      " " +
      moment(startDate).format("DD MMM YYYY") +
      "        to       " +
      moment(endDate).format("DD MMM YYYY");
    page.drawText(dates, {
      x: 200,
      y: height - 65,
      size: 12,
      font: font,
      color: rgb(0, 0.2, 0.6),
    });

    let y = height - 100;
    const rightMargin = 550; // Set right alignment margin

    // Utility function to draw text
    const drawLine = (
      label: string,
      value: number,
      isBold = false,
      indent = 0,
      amountIndent = 0
    ) => {
      const formattedValue = numberWithCommas(value || 0);
      const textWidth = regularFont.widthOfTextAtSize(formattedValue, 12);

      // Draw label (left-aligned with optional indentation)
      page.drawText(label, {
        x: 50 + indent, // More indentation for breakdowns
        y,
        size: 12,
        font: isBold ? font : regularFont,
      });

      // Draw value (right-aligned with optional left shift)
      page.drawText(formattedValue, {
        x: rightMargin - textWidth - amountIndent, // Move breakdown amounts slightly to the left
        y,
        size: 12,
        font: isBold ? font : regularFont,
      });

      y -= 20;
    };

    const drawClassification = (
      title: string,
      data: any,
      isNegative = false
    ) => {
      drawLine(title, isNegative ? -data.total : data.total, true, 0, 0); // Main classification (no indent)
      if (Object.keys(data.breakdown).length > 0) {
        Object.entries(data.breakdown).forEach(([category, amount]) => {
          drawLine(
            `      ${category}`,
            isNegative ? -Number(amount) : Number(amount),
            false,
            30,
            150
          );
          // More padding for breakdown labels (30px)
          // Shift breakdown amounts slightly left (30px)
        });
      }
      y -= 10; // Spacer
    };

    // Revenue
    drawLine("Revenue", incomeStatement.Revenue.total, true);

    // Cost of Goods Sold
    drawClassification("Cost of Goods Sold (COGS)", incomeStatement.COGS, true);

    drawLine("Gross Profit", incomeStatement.GrossProfit, true);
    y -= 10; // Spacer

    // Operating Expenses
    drawClassification(
      "Operating Expenses",
      incomeStatement.OperatingExpenses,
      true
    );
    drawLine("Operating Income", incomeStatement.OperatingIncome, true);
    y -= 10; // Spacer

    // Other Income
    drawLine("Other Income", incomeStatement.OtherIncome);
    drawLine("Net Profit", incomeStatement.NetProfit, true);
    y -= 10; // Spacer

    // Drawings
    drawLine("Drawings", incomeStatement.NonOperatingExpenses.total);
    drawLine(
      "Net Profit after Drawings",
      incomeStatement.NetProfitAfterDrawings,
      true
    );

    // Save and download PDF
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Income_Statement ${startDate} - ${endDate}.pdf`;
    link.click();
    toast({ title: "Downloading Income Statement", status: "success" });
    setShowDatePickerModal(false);
  };

  const isDashboard = location.pathname === "/dashboard";
  return (
    <Stack>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          {!isDashboard && (
            <Button onClick={() => navigate(-1)} variant={"ghost"} mr={2}>
              <ArrowBackIcon w={6} h={6} />
            </Button>
          )}

          <Button
            onClick={() => navigate("/dashboard")}
            fontSize={"2xl"}
            fontWeight={"bold"}
            variant={"ghost"}
          >
            {/* {Cookies.get("businessName")} */}
            {pageName}
          </Button>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              {/* <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  {t("language")}
                </MenuButton>
                <MenuList alignItems={"center"} justifyContent={"center"}>
                  <MenuItem onClick={() => changeLanguage("en")}>
                    <Text>English</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => changeLanguage("sw")}>
                    <Text>Kiswahili</Text>
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={() => changeLanguage("pa")}>
                    <Text>ਪੰਜਾਬੀ</Text>
                  </MenuItem>
                </MenuList>
              </Menu> */}

              {!isSmallScreen && (
                <Button onClick={toggleColorMode}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
              )}
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    src={localStorage.getItem("profilePic") || undefined}
                  />
                </MenuButton>
                <MenuList alignItems={"center"} justifyContent={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      src={localStorage.getItem("profilePic") || undefined}
                    />
                  </Center>
                  <br />
                  <Center>
                    <Text fontSize={"2xl"}>{Cookies.get("userName")}</Text>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem onClick={() => navigate("/ChangePassword")}>
                    {t("ChangePassword")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/Logins")}>
                    {t("ShowLogins")}
                  </MenuItem>
                  <MenuItem onClick={() => setShowDatePickerModal(true)}>
                    {t("Income Statement")}
                  </MenuItem>
                  <MenuItem onClick={() => navigate("/Settings")}>
                    {t("Settings")}
                  </MenuItem>
                  {/* <MenuItem onClick={() => navigate("/PredictionTest")}>
                    {t("Prediction")}
                  </MenuItem> */}
                  <MenuItem justifyContent={"center"}>
                    <Button
                      as={"a"}
                      fontWeight={600}
                      color={"white"}
                      bg={"red.500"}
                      href={"#"}
                      _hover={{
                        bg: "red.400",
                      }}
                      isLoading={isLoading}
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      {t("Logout")}
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>

            {/* LOGOUT CONFIRMATION MODAL */}
            <Modal
              isCentered
              closeOnOverlayClick={false}
              isOpen={showConfirmationModal}
              onClose={() => setShowConfirmationModal(false)}
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent maxWidth="350">
                <ModalCloseButton />
                <ModalHeader> {t("Logout")}?</ModalHeader>
                <ModalBody>{t("Confirm if you want to logout")}</ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onClick={() => {
                        setShowConfirmationModal(false);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      colorScheme="red"
                      isLoading={isLoading}
                      onClick={handleLogout}
                    >
                      {t("Logout")}
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* INCOME STATEMENT DATE PICKER MODAL */}
            <Modal
              isCentered
              closeOnOverlayClick={false}
              isOpen={showDatePickerModal}
              onClose={() => setShowDatePickerModal(false)}
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent maxWidth="350">
                <ModalCloseButton />
                <ModalHeader>Generate Income Statement</ModalHeader>
                <ModalBody>
                  <Text>Select date range for Income Statement</Text>
                  <Stack w={300} pt={4}>
                    <Input
                      placeholder="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <Input
                      placeholder="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      colorScheme="blueGray"
                      onClick={() => {
                        setShowConfirmationModal(false);
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      colorScheme="green"
                      isLoading={isLoading}
                      onClick={() => {
                        getFinancialStatementData();
                        // console.log(incomeStatementData);
                      }}
                    >
                      GENERATE
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Box>
    </Stack>
  );
};

export default Nav;
