import { Stack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";

type DataPoint = {
  primary: string; // Ensure primary is a string (product names)
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type ApiResponse = Record<string, { quantity: number; revenue: number }>;

export default function BarTemplateProductRevenue({ initialData }: { initialData: ApiResponse }) {
  const [data, setData] = useState<Series[]>([]);
  const { t, changeLanguage } = useCustomTranslation();

  useEffect(() => {
    const processData = (data: ApiResponse): Series[] => {
      const revenues: DataPoint[] = [];
      const seen = new Set(); // Track unique product names

      Object.keys(data).forEach((key) => {
        let uniqueKey = key;
        let counter = 1;

        // Ensure unique labels for duplicate revenue values
        while (seen.has(uniqueKey)) {
          uniqueKey = `${key} (${counter++})`; // Append a counter if duplicate
        }
        seen.add(uniqueKey);

        revenues.push({
          primary: uniqueKey,
          secondary: data[key].revenue,
        });
      });

      // If there's only one item, add a small dummy entry
      // if (revenues.length === 1) {
      //   revenues.push({
      //     primary: "__dummy__",
      //     secondary: 0.01, // Small value to maintain structure
      //   });
      // }

      return [{ label: t("Revenue"), data: revenues }];
    };

    setData(processData(initialData));
  }, [initialData, t]);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band",
      position: "left",
      padding: 0.3, // Ensures proper spacing
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(() => [
    {
      getValue: (datum) => datum.secondary,
      scaleType: "linear",
      position: "bottom",
      min: 0, // Force axis to start from 0
    },
  ], []);

  return (
    <Stack w="100%" h={500}>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: useColorModeValue(false, true),
        }}
      />
    </Stack>
  );
}
