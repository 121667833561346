import {
  Button,
  Card,
  Collapse,
  HStack,
  Skeleton,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AxisOptions, Chart } from "react-charts";
import axios from "axios";
import { getHeadersForRequest, numberWithCommas } from "../../utils/functions";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import moment from "moment";

type DataPoint = {
  primary: string; // Ensure primary is a string (month names)
  secondary: number;
};

type Series = {
  label: string;
  data: DataPoint[];
};

type ApiResponse = {
  orders: number[];
  payments: number[];
  expenses: number[];
};

type ProductSaleRaw = {
  year: number;
  month: number;
  product: string;
  totalsale: string;
};

type ChartDataByYear = Record<number, Series[]>;

const processProductSalesByYear = (
  rawData: ProductSaleRaw[],
  t: (key: string) => string
): ChartDataByYear => {
  const monthNames = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const yearlyGroupedData: Record<
    number,
    Record<string, { primary: string; secondary: number }[]>
  > = {};

  rawData.forEach((item) => {
    const { year, month, product, totalsale } = item;
    const monthLabel = monthNames[month - 1];

    if (!yearlyGroupedData[year]) {
      yearlyGroupedData[year] = {};
    }

    if (!yearlyGroupedData[year][product]) {
      yearlyGroupedData[year][product] = [];
    }

    yearlyGroupedData[year][product].push({
      primary: monthLabel,
      secondary: parseFloat(totalsale),
    });
  });

  const chartDataByYear: ChartDataByYear = {};
  for (const year in yearlyGroupedData) {
    chartDataByYear[parseInt(year)] = Object.entries(
      yearlyGroupedData[year]
    ).map(([product, data]) => ({
      label: product,
      data,
    }));
  }

  return chartDataByYear;
};

export default function Bar() {
  const [data, setData] = useState<Series[]>([]);
  const { t, changeLanguage } = useCustomTranslation();
  const [orderSum, setOrderSum] = useState<number>(0);
  const [paymentSum, setPaymentSum] = useState<number>(0);
  const [expenseSum, setExpenseSum] = useState<number>(0);
  const [more, setMore] = useState<boolean>(false);
  const [dataByYear, setDataByYear] = useState<ChartDataByYear>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const isDark = useColorModeValue(false, true);

  useEffect(() => {
    const fetchProductSales = async () => {
      try {
        const headers = await getHeadersForRequest();
        const response = await axios.get<any>(
          `${process.env.REACT_APP_TEST_API_URL}/analytics/products/saleVsProduction`,
          { headers }
        );

        const processed = processProductSalesByYear(
          response.data.dataToSend,
          t
        );
        setDataByYear(processed);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching product sales data:", error);
      }
    };

    fetchProductSales();
  }, [t]);

  const primaryAxis = React.useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      scaleType: "band", // Set the scale type explicitly
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        getValue: (datum) => datum.secondary,
        scaleType: "linear", // Set the scale type explicitly
      },
    ],
    []
  );

  return (
    <>
      {Object.entries(dataByYear).map(([year, yearData]) => (
        <Skeleton
          // height="40px"
          isLoaded={isLoaded}
          fadeDuration={4}
          // bg="blue.500"
          // color="white"
        >
          <Card key={year} mb={6}>
            <Text fontSize="xl" fontWeight="bold" p={4}>
              {year} - Sale by item (kgs)
            </Text>
            <Stack w="100%" h={500}>
              <Chart
                options={{
                  data: yearData,
                  primaryAxis: {
                    getValue: (datum) => datum.primary,
                    scaleType: "band",
                  },
                  secondaryAxes: [
                    {
                      getValue: (datum) => datum.secondary,
                      scaleType: "linear",
                    },
                  ],
                  dark: isDark,
                }}
              />
            </Stack>
          </Card>
        </Skeleton>
      ))}
    </>
  );
}
