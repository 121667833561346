import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  // Table,
  TableCaption,
  // TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  Input,
  Button,
  HStack,
  useToast,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  // IconButton,
  CheckboxGroup,
  Checkbox,
  ModalFooter,
  ButtonGroup,
  Center,
  Card,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { getHeadersForRequest, numberWithCommas } from "../utils/functions";
import {
  categoryData,
  expenseDataFormat,
  paymentDataFormat,
  OrderList,
  AccountList,
} from "../utils/types";
import moment from "moment";
import * as XLSX from "xlsx";
import Nav from "../components/navbar";
import { Loading } from "../components/Loading";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import ExpenseDetailsScreen from "./ExpenseDetails";
// import MuiTableComponent from "../components/MuiTableComponent ";
import MuiThemeProvider from "../MuiThemeProvider";
import {
  CssBaseline,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Delete, Visibility, ModeEdit} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OrderDetailsScreen from "./OrderDetails";
import { getAccountsList } from "../utils/API_calls";

interface Position {
  x: number;
  y: number;
}

const Reports: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  //   FOR PAYMENTS
  const [retrievedPaymentData, setRetrievedPaymentData] = useState<paymentDataFormat[]>([]);
  const [filteredPaymentData, setFilteredPaymentData] = useState<paymentDataFormat[]>([]);
  const [sortConfig, setSortConfig] = useState<{key: keyof paymentDataFormat; direction: "ascending" | "descending";} | null>(null);

  //   FOR SALES Sale
  const [retrievedSaleData, setRetrievedSaleData] = useState<OrderList[]>([]);
  const [filteredSaleData, setFilteredSaleData] = useState<OrderList[]>([]);
  const [sortConfig2, setSortConfig2] = useState<{key: keyof OrderList; direction: "ascending" | "descending";} | null>(null);

  //   FOR EXPENSES
  const [retrievedExpenseData, setRetrievedExpenseData] = useState<expenseDataFormat[]>([]);
  const [filteredExpenseData, setFilteredExpenseData] = useState<expenseDataFormat[]>([]);
  const [sortConfig1, setSortConfig1] = useState<{key: keyof expenseDataFormat;direction: "ascending" | "descending";} | null>(null);

  // Filters
  const [paymentStartDate, setPaymentStartDate] = useState("");
  const [paymentEndDate, setPaymentEndDate] = useState("");
  const [paymentCustomerName, setPaymentCustomerName] = useState("");

  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
  const [expenseCustomerName, setExpenseCustomerName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<string>("");
  const [refInvoice, setRefInvoice] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [traReceiptNumber, setTraReceiptNumber] = useState<string>("");
  const [traReceiptTime, setTraReceiptTime] = useState<string>("");
  const [account, setAccount] = useState<number>(0);
  const [categoriesList, setCategoriesList] = useState<categoryData[]>([]);
  const [category, setCategory] = useState<number | null>(null);
  const [itemId, setItemId] = useState<number | null>(null);
  const [accountName, setAccountName] = useState<string>("Cash");
  const [transactionNumber, setTransactionNumber] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState<boolean>(false);  
  const [accounts, setAccounts] = useState<AccountList[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<number>(0);
  const [selectedTransactionPaymentStatus, setSelectedTransactionPaymentStatus] = useState<boolean>(false);

  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);
  const selectedRowBackgroundColor = useColorModeValue("#e4e4e4", "#8f8f8f");

  const toast = useToast();
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState<string>("");
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });

  useEffect(() => {
    if (tabIndex === 2) {
      retrievePaymentData();
    }
    if (tabIndex === 1) {
      retrieveSaleData();
    }
    if (tabIndex === 0) {
      retrieveExpenseData();
      retrieveCategories();
    }
  }, [tabIndex]);

  useEffect(() => {
    // setIsLoading(true);
    applyExpenseFilters();
  }, [expenseStartDate, expenseEndDate, expenseCustomerName]);

  useEffect(() => {
    // setIsLoading(true);
    applySaleFilters();
  }, [paymentStartDate, paymentEndDate, paymentCustomerName]);

  useEffect(() => {
    if (!selectedTransactionPaymentStatus ) {
      retrieveAccounts();
    }
  }, [selectedTransactionPaymentStatus]);  

  const navigateToOrderDetails = (orderId: string) => {
    navigate(`/OrderDetails/${orderId}`);
  };

  const retrieveAccounts = async () => {
    setModalIsLoading(true);
    const accounts = await getAccountsList();
    if (accounts.success === 1) {
      setAccounts(accounts.data);
    } else {
      setAccounts([]);
    }
    setModalIsLoading(false);
  };

  const retrieveCategories = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<categoryData[]>(
        `${process.env.REACT_APP_TEST_API_URL}/categories/getAll`,
        {
          headers,
        }
      );
      setCategoriesList(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const retrievePaymentData = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<paymentDataFormat[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll/absolute/income`,
        {
          headers,
        }
      );
      const sortedData = response.data.sort((a, b) => {
        if (a["paymentDate"] > b["paymentDate"]) return -1;
        if (a["paymentDate"] < b["paymentDate"]) return 1;
        return 0;
      });
      setRetrievedPaymentData(sortedData);
      setFilteredPaymentData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const retrieveSaleData = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<OrderList[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll`,
        {
          headers,
        }
      );

      console.log(response.data);
      const sortedData = response.data.sort((a, b) => {
        if (a["invoiceDate"] > b["invoiceDate"]) return -1;
        if (a["invoiceDate"] < b["invoiceDate"]) return 1;
        return 0;
      });

      setRetrievedSaleData(sortedData);
      setFilteredSaleData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const retrieveExpenseData = async () => {
    try {
      setIsLoading(true);

      const headers = await getHeadersForRequest();

      const response = await axios.get<expenseDataFormat[]>(
        `${process.env.REACT_APP_TEST_API_URL}/transactions/getAll/absolute/expenses`,
        {
          headers: headers,
        }
      );
      const sortedData = response.data.sort((a, b) => {
        if (a["invoiceDate"] > b["invoiceDate"]) return -1;
        if (a["invoiceDate"] < b["invoiceDate"]) return 1;
        return 0;
      });

      setRetrievedExpenseData(sortedData);
      setFilteredExpenseData(sortedData);
      setIsLoading(false);
      // handleSort1("invoiceDate");
      // console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const applyPaymentFilters = () => {
    let filteredData = [...retrievedPaymentData];

    if (paymentStartDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.paymentDate) >= new Date(paymentStartDate)
      );
    }

    if (paymentEndDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.paymentDate) <= new Date(paymentEndDate)
      );
    }

    if (paymentCustomerName) {
      filteredData = filteredData.filter((data) =>
        data.name.toLowerCase().includes(paymentCustomerName.toLowerCase())
      );
    }

    setFilteredPaymentData(filteredData);
  };

  const applySaleFilters = () => {
    let filteredData = [...retrievedSaleData];

    if (paymentStartDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) >= new Date(paymentStartDate)
      );
    }

    if (paymentEndDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) <= new Date(paymentEndDate)
      );
    }

    if (paymentCustomerName) {
      filteredData = filteredData.filter((data) =>
        data.name.toLowerCase().includes(paymentCustomerName.toLowerCase())
      );
    }

    setFilteredSaleData(filteredData);
  };

  const applyExpenseFilters = () => {
    let filteredData = [...retrievedExpenseData];

    if (expenseStartDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) >= new Date(expenseStartDate)
      );
    }

    if (expenseEndDate) {
      filteredData = filteredData.filter(
        (data) => new Date(data.invoiceDate) <= new Date(expenseEndDate)
      );
    }

    if (expenseCustomerName) {
      filteredData = filteredData.filter((data) =>
        data.name.toLowerCase().includes(expenseCustomerName.toLowerCase()) || data.supplierName?.toLowerCase().includes(expenseCustomerName.toLowerCase()) || data.expenseCategory?.toLowerCase().includes(expenseCustomerName.toLowerCase())
      );
    }

    setFilteredExpenseData(filteredData);
  };

  const handleSort = (key: keyof paymentDataFormat) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filteredPaymentData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredPaymentData(sortedData);
  };

  const handleSort1 = (key: keyof expenseDataFormat) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig1 &&
      sortConfig1.key === key &&
      sortConfig1.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig1({ key, direction });
    const sortedData = [...filteredExpenseData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredExpenseData(sortedData);
  };

  const handleSort2 = (key: keyof OrderList) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig2 &&
      sortConfig2.key === key &&
      sortConfig2.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig2({ key, direction });
    const sortedData = [...filteredSaleData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setFilteredSaleData(sortedData);
  };

  const exportToExcel = (data: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleTransactionClick = (transaction: expenseDataFormat) => {
    setShowDetailsModal(true);

    // console.log(transaction.category);
    // console.log(transaction.itemId);

    let delivered = transaction.deliveryStatus ? "delivered" : "";
    let paid = transaction.paymentStatus ? "paid" : "";
    let physical = transaction.physicalDeliveryNote ? "physical" : "";
    let tra = transaction.traReceiptNumber && transaction.traTime ? "tra" : "";
    let vat = transaction.hasVat ? "vat" : "";
    let newGroupValues = [delivered, paid, physical, tra, vat];

    setGroupValues(newGroupValues);
    setDeliveryNoteNumber(transaction.deliveryNoteNumber);
    setRefInvoice(transaction.refInvoice);
    setTransactionId(transaction.id);
    setTraReceiptNumber(transaction.traReceiptNumber);
    setTraReceiptTime(transaction.traTime);
    setAccount(transaction.account);
    setAccountName(transaction.accountName);
    setTransactionNumber(transaction.transactionNumber);
    setCategory(transaction.expenseCategoryId);
    setItemId(transaction.itemId);
    setSelectedTransactionPaymentStatus(transaction.paymentStatus);
    setSelectedAccount(transaction.account);
  };

  const updateTransactionDetails = async () => {
    // console.log(transactionId);
    // return;

    if (groupValues.includes("paid") && selectedAccount === 0) {
      toast({
        title: "Please select an account",
        duration: 3000,
        status: "warning",
      });
      return;
    }

    setIsUpdating(true);
    try {
      const data = {
        paymentStatus: groupValues.includes("paid"),
        paymentDate: groupValues.includes("paid") ? new Date() : null,
        transactionId: transactionId,
        deliveryStatus: groupValues.includes("delivered"),
        deliveryDate: groupValues.includes("delivered") ? new Date() : null,
        invoiceDate: new Date(),
        physicalDeliveryNote: groupValues.includes("physical"),
        deliveryNoteNumber: groupValues.includes("physical")
          ? deliveryNoteNumber
          : "",
        traReceiptNumber: traReceiptNumber,
        traReceiptTime: traReceiptTime,
        refInvoice: refInvoice,
        account: account !== selectedAccount ? selectedAccount : account,
        category: category,
        itemId: itemId,
        hasVat: groupValues.includes("vat")
      };

      // if (data.deliveryNoteNumber === "" && data.physicalDeliveryNote) {
      //   toast({ title: "Invalid Delivery Note Number" });
      //   return;
      // }

      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/update`,
          {
            data,
          },
          { headers }
        )
        .then((response) => {
          console.log(response.data);
          toast({
            title: "Transaction details updated successfully",
            duration: 3000,
            status: "success",
          });
          // Find the index of the item in the array based on the ID
          const index = filteredExpenseData.findIndex(
            (item) => item.itemId === response.data.itemId
          );

          // Check if the item exists in the array
          if (index !== -1) {
            // Update the existing item with the new details
            filteredExpenseData[index] = {
              ...filteredExpenseData[index],
              ...response.data,
            };
          } else {
            console.warn("Item not found in the array.");
          }
          setShowDetailsModal(false);
          setIsUpdating(false);
          setSelectedRowKey(null);
        })
        .catch((error) => {
          console.error(error);
          setIsUpdating(false);
          toast({
            title: "Error updating transaction",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {
      setIsUpdating(false);
    }
  };

  const voidTransaction = async () => {
    try {
      const headers = await getHeadersForRequest();
      await axios
        .post(
          `${process.env.REACT_APP_TEST_API_URL}/transactions/delete`,
          {
            transactionId: transactionId,
          },
          { headers }
        )
        .then((response) => {
          // console.log(response.data);
          toast({
            title: "Transaction VOID successful",
            duration: 3000,
            status: "success",
          });
          setShowDetailsModal(false);
          retrieveExpenseData();
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Error, please try again later",
            duration: 3000,
            status: "error",
          });
        });
    } catch (error) {
      // toast.show({ description: error.toString() });
      console.error(error);
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredExpenseData.length)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Stack>
      <Stack position="sticky" top={0} zIndex={1} width="full">
        <Nav pageName={"Reports"} />
      </Stack>
      {isLoading ? (
        <Center h="90dvh">
          <Loading />
        </Center>
      ) : (
        <Stack>
          <Tabs
            isLazy
            onChange={(index) => {
              setTabIndex(index);
              console.log("Index " + index);
            }}
            index={tabIndex}
          >
            <TabList position="sticky" top={5}>
              <Tab>Expenses</Tab>
              <Tab>Sales</Tab>
              <Tab>Customer Payments</Tab>
            </TabList>
            <TabPanels>
              {/* EXPENSES */}
              <TabPanel>
                <Box mb={10}>
                  <HStack direction="row" spacing={4} flexWrap={"wrap"}>
                    <HStack pr={5}>
                      <Text>Start Date</Text>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="Start Date"
                          type="date"
                          value={expenseStartDate}
                          onChange={(e) => setExpenseStartDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>
                    <HStack pr={5}>
                      <Text>End Date</Text>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="End Date"
                          type="date"
                          value={expenseEndDate}
                          onChange={(e) => setExpenseEndDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Text>Description</Text>
                      <Stack>
                        <Input
                          placeholder="Description"
                          value={expenseCustomerName}
                          onChange={(e) =>
                            setExpenseCustomerName(e.target.value)
                          }
                        />
                      </Stack>
                    </HStack>
                    <HStack>
                      <Button onClick={applyExpenseFilters}>
                        Apply Filters
                      </Button>
                      <Button
                        onClick={() =>
                          exportToExcel(filteredExpenseData, "Expenses")
                        }
                      >
                        Export to Excel
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
                {/* <TableContainer maxWidth={"100%"}>
                  <Table variant={"striped"}>
                    <TableCaption>Data fetched from the server</TableCaption>
                    <Thead>
                      <Tr>
                        <Th fontSize={"lg"} onClick={() => handleSort1("id")}>
                          ID
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("invoiceDate")}
                        >
                          Date
                        </Th>
                        <Th fontSize={"lg"} onClick={() => handleSort1("name")}>
                          Description
                        </Th>
                        <Th fontSize={"lg"} onClick={() => handleSort1("name")}>
                          Account
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("paymentStatus")}
                          // itemType="boolean"
                        >
                          Status
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort1("totalAmount")}
                          isNumeric
                        >
                          Total Amount
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredExpenseData.map(
                        (data: expenseDataFormat, key) => (
                          <Tr
                            key={key}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setPosition({ x: e.pageX, y: e.pageY });
                              if (data.isVoid) {
                              } else {
                                handleTransactionClick(data);
                              }
                            }}
                            onClick={() => {
                              setShowPreviewModal(true);
                              setOrderId(data.transactionNumber.toString());
                            }}
                          >
                            <Td>{data.transactionNumber}</Td>
                            <Td>
                              {moment(data.invoiceDate).format("DD MMM YYYY")}
                            </Td>
                            <Td>{data.name}</Td>
                            <Td>{data.accountName}</Td>
                            <Td
                              fontWeight={"bolder"}
                              color={data.paymentStatus ? "unset" : "red"}
                            >
                              {data.paymentStatus ? "PAID" : "NOT PAID"}
                            </Td>
                            <Td isNumeric>
                              {numberWithCommas(data.price * data.qty)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer> */}

                {/* <MuiTableComponent/> */}

                <MuiThemeProvider>
                  <CssBaseline /> {/* Ensure consistent MUI baseline styles */}
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => handleSort1("id")}>
                            ID
                          </TableCell>
                          <TableCell onClick={() => handleSort1("invoiceDate")}>
                            Date
                          </TableCell>
                          <TableCell onClick={() => handleSort1("name")}>
                            Description
                          </TableCell>
                          <TableCell onClick={() => handleSort1("name")}>
                            Account
                          </TableCell>
                          <TableCell
                          // onClick={() => handleSort1("paymentStatus")}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort1("paymentStatus")}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            onClick={() => {
                              handleSort1("supplierName");
                            }}
                          >
                            Supplier Name
                          </TableCell>
                          <TableCell
                          // onClick={() => handleSort1("paymentStatus")}
                          >
                            Supplier Doc
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort1("totalAmount")}
                            align={"right"}
                          >
                            Total Amount
                          </TableCell>
                          <TableCell
                            // onClick={() => handleSort1("tax")}
                            align={"right"}
                          >
                            Tax
                          </TableCell>
                          <TableCell
                            // onClick={() => handleSort1("totalAmount")}
                            align={"right"}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? filteredExpenseData.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : filteredExpenseData
                        ).map((data: expenseDataFormat, key) => (
                          <TableRow
                            hover
                            style={{
                              backgroundColor:
                                selectedRowKey === data.itemId
                                  ? selectedRowBackgroundColor
                                  : data.supplierName && !data.hasSupplierDoc
                                  ? "orange"
                                  : "transparent",
                              color: "black",
                            }}
                            key={key}
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setSelectedRowKey(data.itemId);
                              setPosition({ x: e.pageX, y: e.pageY });
                              if (data.isVoid) {
                              } else {
                                handleTransactionClick(data);
                              }
                            }}
                            // onClick={() => {
                            //   setShowPreviewModal(true);
                            //   setOrderId(data.transactionNumber.toString());
                            // }}
                          >
                            <TableCell
                              style={{
                                width: 100,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.transactionNumber}
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {moment(data.invoiceDate).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell
                              style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.name}
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/AccountStatement/${data.account}/${data.accountName}`
                                  )
                                }
                              >
                                {data.accountName}
                              </Button>
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.expenseCategory}
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.paymentStatus ? "PAID" : "NOT PAID"}
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/SupplierOrders/${data.supplierId}/${data.supplierName}`
                                  )
                                }
                              >
                                {data.supplierName}
                              </Button>
                            </TableCell>
                            <TableCell
                              style={{
                                width: 160,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.hasSupplierDoc ? "YES" : "NO"}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {numberWithCommas(data.price * data.qty)}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.hasVat
                                ? numberWithCommas(
                                    data.price * data.qty -
                                      (data.price * data.qty) / 1.18
                                  )
                                : 0}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedRowKey(data.itemId);
                                  setPosition({ x: e.pageX - 300, y: e.pageY });
                                  if (data.isVoid) {
                                  } else {
                                    handleTransactionClick(data);
                                  }
                                }}
                              >
                                <ModeEdit />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setShowPreviewModal(true);
                                  setOrderId(data.transactionNumber.toString());
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                            {/* <TableCell
                              align="right"
                              style={{
                                width: 300,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              40,
                              100,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={11}
                            count={filteredExpenseData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </MuiThemeProvider>
              </TabPanel>

              {/* SALES*/}
              <TabPanel>
                <Box mb={10}>
                  <HStack flexWrap={"wrap"}>
                    <HStack pr={5}>
                      <Stack>
                        <Text>Start Date</Text>
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="Start Date"
                          type="date"
                          value={paymentStartDate}
                          onChange={(e) => setPaymentStartDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>End Date</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="End Date"
                          type="date"
                          value={paymentEndDate}
                          onChange={(e) => setPaymentEndDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>Name</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          placeholder="Customer Name"
                          value={paymentCustomerName}
                          onChange={(e) =>
                            setPaymentCustomerName(e.target.value)
                          }
                        />
                      </Stack>
                    </HStack>

                    <HStack>
                      {/* <Text></Text> */}
                      <Button onClick={applyPaymentFilters}>
                        Apply Filters
                      </Button>
                      <Button
                        onClick={() => exportToExcel(filteredSaleData, "Sales")}
                      >
                        Export to Excel
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
                <MuiThemeProvider>
                  <CssBaseline /> {/* Ensure consistent MUI baseline styles */}
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => handleSort2("id")}>
                            Invoice
                          </TableCell>
                          <TableCell onClick={() => handleSort2("invoiceDate")}>
                            Date
                          </TableCell>
                          <TableCell onClick={() => handleSort2("name")}>
                            Customer Name
                          </TableCell>
                          <TableCell onClick={() => handleSort2("name")}>
                            Del. Note
                          </TableCell>

                          <TableCell onClick={() => handleSort2("name")}>
                            Receipt No.
                          </TableCell>
                          <TableCell onClick={() => handleSort2("name")}>
                            Time
                          </TableCell>
                          <TableCell
                            // onClick={() => handleSort2("totalAmount")}
                            align={"right"}
                          >
                            Fiscal Receipt
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort2("totalAmount")}
                            align={"right"}
                          >
                            Actions
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort2("totalAmount")}
                            align={"right"}
                          >
                            Total Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? filteredSaleData.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : filteredSaleData
                        ).map((data: OrderList, key) => (
                          <TableRow
                            style={{
                              backgroundColor:
                                data.traReceiptNumber && data.traTime
                                  ? "transparent"
                                  : "orange",
                              color: "black",
                            }}
                            hover
                            key={key}
                            // onContextMenu={(e) => {
                            //   e.preventDefault();
                            //   setPosition({ x: e.pageX, y: e.pageY });
                            //   if (data.isVoid) {
                            //   } else {
                            //     handleTransactionClick(data);
                            //   }
                            // }}
                            // onClick={() => {
                            //   setShowPreviewModal(true);
                            //   setOrderId(data.transactionNumber.toString());
                            // }}
                          >
                            <TableCell style={{ width: 100 }}>
                              {data.transactionNumber}
                            </TableCell>
                            <TableCell style={{ width: 160 }}>
                              {moment(data.invoiceDate).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell style={{ width: 300 }}>
                              {data.name}
                            </TableCell>
                            <TableCell style={{ width: 100 }}>
                              {data.deliveryNoteNumber}
                            </TableCell>

                            <TableCell style={{ width: 100 }}>
                              {data.traReceiptNumber}
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                              {moment(data.traTime, "HHmmss").format(
                                "HH:mm:ss"
                              )}
                            </TableCell>

                            <TableCell align="right" style={{ width: 50 }}>
                              <Button
                                variant={"solid"}
                                onClick={() => {
                                  const url = `${
                                    data.QRLink +
                                    data.traCode +
                                    data.traReceiptNumber +
                                    "_" +
                                    data.traTime
                                  }`;
                                  window.open(url, "_blank");
                                }}
                              >
                                Verify
                              </Button>
                            </TableCell>
                            <TableCell align="right" style={{ width: 50 }}>
                              <IconButton
                                onClick={() => {
                                  setShowPreviewModal(true);
                                  setOrderId(data.transactionNumber.toString());
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right" style={{ width: 200 }}>
                              {numberWithCommas(data.totalAmount)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={9}
                            count={filteredSaleData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </MuiThemeProvider>
              </TabPanel>

              {/* CUSTOMER PAYMENTS*/}
              <TabPanel>
                <Box mb={10}>
                  <HStack flexWrap={"wrap"}>
                    <HStack pr={5}>
                      <Stack>
                        <Text>Start Date</Text>
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="Start Date"
                          type="date"
                          value={paymentStartDate}
                          onChange={(e) => setPaymentStartDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>End Date</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          minW={"100px"}
                          placeholder="End Date"
                          type="date"
                          value={paymentEndDate}
                          onChange={(e) => setPaymentEndDate(e.target.value)}
                        />
                      </Stack>
                    </HStack>

                    <HStack pr={5}>
                      <Stack>
                        <Text>Name</Text>{" "}
                      </Stack>
                      <Stack>
                        <Input
                          placeholder="Customer Name"
                          value={paymentCustomerName}
                          onChange={(e) =>
                            setPaymentCustomerName(e.target.value)
                          }
                        />
                      </Stack>
                    </HStack>

                    <HStack>
                      {/* <Text></Text> */}
                      <Button onClick={applyPaymentFilters}>
                        Apply Filters
                      </Button>
                      <Button
                        onClick={() =>
                          exportToExcel(filteredPaymentData, "Payments")
                        }
                      >
                        Export to Excel
                      </Button>
                    </HStack>
                  </HStack>
                </Box>
                {/* <TableContainer>
                  <Table variant="striped">
                    <TableCaption>Data fetched from the server</TableCaption>
                    <Thead>
                      <Tr>
                        <Th fontSize={"lg"} onClick={() => handleSort("id")}>
                          ID
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort("paymentDate")}
                        >
                          Date
                        </Th>
                        <Th fontSize={"lg"} onClick={() => handleSort("name")}>
                          Customer Name
                        </Th>
                        <Th
                          fontSize={"lg"}
                          onClick={() => handleSort("totalAmount")}
                          isNumeric
                        >
                          Total Amount
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredPaymentData.map(
                        (data: paymentDataFormat, key) => (
                          <Tr
                            key={key}
                            backgroundColor={data.isVoid ? "red" : "unset"}
                          >
                            <Td>{data.id}</Td>
                            <Td>
                              {moment(data.paymentDate).format("DD MMM YYYY")}
                            </Td>
                            <Td>{data.name}</Td>
                            <Td isNumeric>
                              {numberWithCommas(data.totalAmount)}
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th>id</Th>
                        <Th>paymentDate</Th>
                        <Th>name</Th>
                        <Th isNumeric>totalAmount</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer> */}
                <MuiThemeProvider>
                  <CssBaseline /> {/* Ensure consistent MUI baseline styles */}
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell onClick={() => handleSort("id")}>
                            ID
                          </TableCell>
                          <TableCell onClick={() => handleSort("paymentDate")}>
                            Date
                          </TableCell>
                          <TableCell onClick={() => handleSort("name")}>
                            Customer Name
                          </TableCell>
                          <TableCell
                            onClick={() => handleSort("totalAmount")}
                            align={"right"}
                          >
                            Total Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(rowsPerPage > 0
                          ? filteredPaymentData.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : filteredPaymentData
                        ).map((data: paymentDataFormat, key) => (
                          <TableRow
                            hover
                            key={key}
                            // onContextMenu={(e) => {
                            //   e.preventDefault();
                            //   setPosition({ x: e.pageX, y: e.pageY });
                            //   if (data.isVoid) {
                            //   } else {
                            //     handleTransactionClick(data);
                            //   }
                            // }}
                            // onClick={() => {
                            //   setShowPreviewModal(true);
                            //   setOrderId(data.transactionNumber.toString());
                            // }}
                          >
                            <TableCell style={{ width: 100 }}>
                              {data.id}
                            </TableCell>
                            <TableCell style={{ width: 160 }}>
                              {moment(data.paymentDate).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell style={{ width: 300 }}>
                              {data.name}
                            </TableCell>
                            <TableCell align="right" style={{ width: 300 }}>
                              {numberWithCommas(data.totalAmount)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={6}
                            count={filteredExpenseData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </MuiThemeProvider>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      )}
      {/* TRANSACTION DETAILS MODAL */}
      {showDetailsModal && !modalIsLoading ? (
        <Card
          position={"absolute"}
          top={position.y}
          left={position.x}
          // backgroundColor={"white"}
          padding={"10px"}
          border={"1px solid #ccc"}
          // boxShadow={ '0px 0px 10px rgba(0,0,0,0.2)'}
          zIndex={1000}

          // closeOnOverlayClick={false}
          // isOpen={showDetailsModal}
          // onClose={() => setShowDetailsModal(false)}
        >
          {/* <Heading>Transaction {transactionNumber} Details</Heading> */}

          <HStack justifyContent={"space-between"}>
            <Text>Details:</Text>
            <MuiThemeProvider>
              <IconButton onClick={() => setShowConfirmationModal(true)}>
                <Delete color="error" />
              </IconButton>
            </MuiThemeProvider>
            {/* <IconButton
            // size="md"
            fontSize="lg"
            variant="ghost"
            // color="current"
            marginLeft="2"
            onClick={() => setShowConfirmationModal(true)}
            icon={<FaTrash color="red" />}
            // aria-label={`Go to home`}
          /> */}
          </HStack>

          <Stack>
            <CheckboxGroup
              onChange={(values: string[]) => setGroupValues(values)}
              value={groupValues}
            >
              <Checkbox value="paid" my={2}>
                Paid
              </Checkbox>
              <Checkbox value="delivered" my={2}>
                Delivered
              </Checkbox>
              <Checkbox value="physical" my={2}>
                {tabIndex === 1
                  ? "Physical Delivery Note"
                  : "Supplier Reference Docs"}
              </Checkbox>
              {tabIndex === 1 && (
                <Checkbox value="tra" my={2}>
                  TRA Receipt
                </Checkbox>
              )}
              <Checkbox value="vat" my={2}>
                Has VAT
              </Checkbox>
            </CheckboxGroup>
          </Stack>

          {groupValues.includes("paid") &&
            !selectedTransactionPaymentStatus && (
              <>
                <Text>Pick the account used for payment </Text>
                <Select
                  onChange={(e) => setSelectedAccount(parseInt(e.target.value))}
                >
                  <option value={""}>{"Pick Account"}</option>
                  {accounts.map((account) => (
                    <option
                      // label={customer.name}
                      value={account.id.toString()}
                    >
                      {account.name}
                    </option>
                  ))}
                </Select>
              </>
            )}

          <Stack>
            {groupValues.includes("physical") && (
              <>
                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  defaultValue={refInvoice}
                  onChange={(e) => setRefInvoice(e.target.value)}
                  placeholder={"Enter reference invoice number"}
                />

                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  defaultValue={deliveryNoteNumber}
                  onChange={(e) => setDeliveryNoteNumber(e.target.value)}
                  placeholder={"Enter delivery note number"}
                />
              </>
            )}
          </Stack>

          {/* {category === null && ( */}
          <Stack pb={2}>
            <Select
              flex={1}
              onChange={(e) => setCategory(parseInt(e.target.value))}
              value={category ? category : ""}
            >
              <option value={""}>{"-- Expense Category --"}</option>

              {categoriesList.map((category) => (
                <option value={category.id.toString()}>{category.name}</option>
              ))}
            </Select>
          </Stack>
          {/* )} */}

          <Stack>
            {groupValues.includes("tra") && (
              <>
                <Input
                  // color={"white"}
                  fontSize="md"
                  pl={5}
                  // keyboardType="number-pad"
                  backgroundColor="transparent"
                  variant={"underlined"}
                  defaultValue={traReceiptNumber}
                  onChange={(e) => setTraReceiptNumber(e.target.value)}
                  placeholder={"Enter TRA Receipt Number"}
                />

                <Input
                  fontSize="md"
                  pl={5}
                  backgroundColor="transparent"
                  variant={"underlined"}
                  defaultValue={traReceiptTime}
                  onChange={(e) => setTraReceiptTime(e.target.value)}
                  placeholder={"Enter Receipt Time"}
                />
              </>
            )}
          </Stack>
          <ButtonGroup>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onClick={() => {
                setShowDetailsModal(false);
                setSelectedRowKey(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateTransactionDetails();
                // console.log(transactionId)
              }}
              isLoading={isUpdating}
            >
              Update
            </Button>
          </ButtonGroup>
        </Card>
      ) : // <Loading />
      null}

      {/* CONFIRMATION MODAL */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      >
        <ModalContent maxWidth="350">
          <ModalCloseButton />
          <ModalHeader>Alert</ModalHeader>
          <ModalBody>
            <Stack>
              <Text textAlign={"center"}>
                Are you sure you want to VOID this receipt?
              </Text>
              <Text textAlign={"center"}>THIS ACTION IS IRREVERSIBLE!</Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                No
              </Button>
              <Button
                bg={"red"}
                onClick={() => {
                  voidTransaction();
                }}
              >
                Yes
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* PREVIEW MODAL */}
      <Modal
        size="xl"
        isCentered
        closeOnOverlayClick={false}
        isOpen={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
      >
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Preview</ModalHeader>
          <ModalBody>
            <Stack>
              {tabIndex === 0 ? (
                <ExpenseDetailsScreen ID={orderId} />
              ) : (
                <OrderDetailsScreen ID={orderId} />
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="red"
                onClick={() => {
                  setShowPreviewModal(false);
                }}
              >
                Close
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default Reports;
